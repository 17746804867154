import { Component, OnInit, ViewEncapsulation, Input, SimpleChanges, AfterContentInit } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { KeycloakService } from 'keycloak-angular';
import { GridOptions } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as XLSX from 'xlsx'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { font2 } from '../../fonts/encryption';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-crophistory',
  templateUrl: './crophistory.component.html',
  styleUrls: ['./crophistory.component.scss']
})
export class CrophistoryComponent implements OnInit {
  farmerId: any = '';
  plotCodes: any[] = [];
  irrigationData: any = [];
  irrigationDataMap: any = []
  fertilisationData: any = [];
  fertilisationDataMap: any[] = []
  sprayingData: any = [];
  sprayingDataMap: any[] = [];
  harvestData: any = [];
  harvestDataMap: any[] = [];

  codes: any = [];
  code: any = '';
  public langStatus: any;
  gridOptions: GridOptions = {
    columnDefs: [],
    rowData: [],
    rowSelection: 'multiple',
    animateRows: true,
    pagination: true,
    paginationPageSize: 10
  };
  irrigationColumnDefs: any[] = [];
  fertilisationColumnDefs: any[] = [];
  sprayingColumnDefs: any[] = [];
  harvestColumnDefs: any[] = [];
  defaultColDef: any = {
    flex: 1,
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: {
      buttons: ['reset', 'apply'],
      filterOptions: ['contains', 'notContains', 'equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
    }
  }
  selectType: any[] = ['Irrigation', 'Fertilisation', 'Spraying', 'Harvest']
  columnDefs: any[] = [
    {
      headerName: 'Date',
      field: 'datetime',
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset', 'apply'],
      },
    },
  ];
  isPlots: boolean = false;
  isIrrigation: boolean = false;
  isFertilisation: boolean = false;
  isSpraying: boolean = false;
  isHarvest: boolean = false;
  selected: any = '';
  select: any;
  selectedPlot: boolean = false;
  irrigationQuantity: number = 0;
  irrigationHours: number = 0;
  fertilisationDossage: number = 0;
  fertilisationAmount: number = 0;
  sprayingSolution: number = 0;
  sprayingDosage: number = 0;
  sprayingAmount: number = 0;
  harvestSum: number = 0;
  headColumns: any[] = [];
  headColumnsSum: any[] = [];
  SumTable: any;
  IrrigationSum: any[] = [];
  FertilisationSum: any[] = [];
  SpraySum: any[] = [];
  HarvestSum: any[] = [];
  elArray: any[] = [];
  splitArray: any
  @Input() plotValueCrop: any;
  @Input() plotTypeCrop: any;
  @Input() plotChoose: any;
  @Input() tabStatus: any;
  @Input() eventChild: any;
  @Input() eventParent: any;
  @Input() parentCodeBoolean: any;
  @Input() childCodeBoolean: any;
  @Input() parentCode: any;
  @Input() childCode: any;
  @Input() parentBox: any;
  @Input() childBox: any;
  @Input() setAllStatus: any;
  @Input() startingDateString: any
  @Input() endingDateString: any
  @Input() afmFarmer: any
  @Input() firstName: any
  @Input() lastName: any
  crop_id: any[] = [];
  parentCropId: any;
  dateRange: any;
  cropType: any;
  // cropTypeArray: any[] = [];

  constructor(
    private req: RequestService<any>,
    private keycloak: KeycloakService,
    private datePipe: DatePipe,
    public translate: TranslateService) { }

  ngOnInit() {
    this.langStatus = this.translate.store.currentLang
    this.columnsTable();
    this.getAllData()
  }

  getAllData() {
    this.req.getPlotFarmerHistory('?crop_usage=True').subscribe((res) => {
      this.plotCodes = res
    })
  }


  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.plotChoose.length
      if (this.tabStatus == 1 && this.plotTypeCrop == 'Irrigation' || this.plotTypeCrop == 'Άρδευση') {
        this.isPlots = false;
        this.isIrrigation = true;
        this.isFertilisation = false;
        this.isHarvest = false;
        this.isSpraying = false;
        if (this.plotChoose.length == 0) {
          this.noData()
        } else if (this.plotChoose.length > 0) {
          this.getIrrigationData()
        }
        else if (this.setAllStatus) {
          this.getAllIrrigation()
        }
      } else if (this.tabStatus == 1 && this.plotTypeCrop == 'Fertilisation' || this.plotTypeCrop == 'Λίπανση') {
        this.isPlots = false;
        this.isIrrigation = false;
        this.isFertilisation = true;
        this.isHarvest = false;
        this.isSpraying = false;
        if (this.plotChoose.length == 0) {
          this.noData()
        } else if (this.plotChoose.length > 0) {
          this.getFertilisationData()
        } else if (this.setAllStatus) {
          this.getAllFertilisation()
        }
      } else if (this.tabStatus == 1 && this.plotTypeCrop == 'Spraying' || this.plotTypeCrop == 'Φυτοπροστασία') {
        this.isPlots = false;
        this.isIrrigation = false;
        this.isFertilisation = false;
        this.isHarvest = false;
        this.isSpraying = true;
        if (this.plotChoose.length == 0) {
          this.noData()
        } else if (this.plotChoose.length > 0) {
          this.getSprayingData()
        } else if (this.setAllStatus) {
          this.getAllSprayingData()
        }
      } else if (this.tabStatus == 1 && this.plotTypeCrop == 'Harvest' || this.plotTypeCrop == 'Συγκομιδή') {
        this.isPlots = false;
        this.isIrrigation = false;
        this.isFertilisation = false;
        this.isHarvest = true;
        this.isSpraying = false;
        if (this.plotChoose.length == 0) {
          this.noData()
        } else if (this.plotChoose.length > 0) {
          this.getHarvestData()
        } else if (this.setAllStatus) {
          this.getAllHarvestData()
        }
      }
    }, 50);
  }

  columnsTable() {
    this.langStatus = this.translate.store.currentLang;
    if (this.langStatus === 'en') {
      this.irrigationColumnDefs = [
        { headerName: 'Plot', field: 'plot_code', resizable: true },
        { headerName: 'Date', field: 'datetime', resizable: true },
        { headerName: 'Hours', field: 'hours', resizable: true },
        { headerName: 'Frequency', field: 'irrigation_frequency', resizable: true },
        { headerName: 'Method', field: 'irrigation_method', resizable: true },
        { headerName: 'Quantity', field: 'quantity', resizable: true },
        { headerName: 'Action', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ];

      this.fertilisationColumnDefs = [
        { headerName: 'Plot', field: 'plot_code', resizable: true },
        { headerName: 'Agronomist', field: 'agronomist', resizable: true },
        { headerName: 'Operator', field: 'operator', resizable: true },
        { headerName: 'Batch', field: 'batch_number', resizable: true },
        { headerName: 'Com.Name', field: 'commercial_name', resizable: true },
        { headerName: 'Ch.Composition', field: 'chemical_composition', resizable: true },
        { headerName: 'Date', field: 'datetime', resizable: true },
        { headerName: 'Dosage', field: 'dosage', resizable: true },
        { headerName: 'D.Units', field: 'dosage_measurement_units', resizable: true },
        { headerName: 'By', field: 'dosage_applied_by', resizable: true },
        { headerName: 'Amount', field: 'amount_applied', resizable: true },
        { headerName: 'A.Units', field: 'amount_measurement_units', resizable: true },
        { headerName: 'Weather', field: 'weather_conditions', resizable: true },
        { headerName: 'Method', field: 'implementation_method', resizable: true },
        { headerName: 'Stage', field: 'cultivation_stage', resizable: true },
        { headerName: 'Action', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ]

      this.sprayingColumnDefs = [
        { headerName: 'Plot', field: 'plot_code', resizable: true },
        { headerName: 'Operator', field: 'operator', resizable: true },
        { headerName: 'Batch', field: 'batch_number', resizable: true },
        { headerName: 'Com.Name', field: 'commercial_name', resizable: true },
        { headerName: 'Substance', field: 'active_substance', resizable: true },
        { headerName: 'Date', field: 'datetime', resizable: true },
        { headerName: 'Dosage', field: 'dosage', resizable: true },
        { headerName: 'Units', field: 'dosage_measurement_units', resizable: true },
        { headerName: 'By', field: 'dosage_applied_by', resizable: true },
        { headerName: 'Solution', field: 'amount_of_solution', resizable: true },
        { headerName: 'Units', field: 'amount_measurement_units', resizable: true },
        { headerName: 'By', field: 'amount_of_solution_applied_by', resizable: true },
        { headerName: 'Amount', field: 'amount_applied', resizable: true },
        { headerName: 'Units', field: 'amount_measurement_units', resizable: true },
        { headerName: 'Stage', field: 'cultivation_stage', resizable: true },
        { headerName: 'Justification', field: 'justification', resizable: true },
        { headerName: 'PHI', field: 'phi', resizable: true },
        { headerName: 'Items', field: 'amount_of_item', resizable: true },
        { headerName: 'Action', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ];

      this.harvestColumnDefs = [
        { headerName: 'Plot', field: 'plot_code', resizable: true },
        { headerName: 'Equipment Cleaning', field: 'cleaning_of_harvesting_equipment', resizable: true },
        { headerName: 'Client', field: 'client', resizable: true },
        { headerName: 'Date', field: 'datetime', resizable: true },
        { headerName: 'Lot Number', field: 'lot_number', resizable: true },
        { headerName: 'Sum', field: 'summ', resizable: true },
        { headerName: 'Action', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ]
    }

    else {
      this.irrigationColumnDefs = [
        { headerName: 'Αγροτεμάχιο', field: 'plot_code', resizable: true },
        { headerName: 'Ημερομηνία', field: 'datetime', resizable: true },
        { headerName: 'Ώρες', field: 'hours', resizable: true },
        { headerName: 'Συχνότητα', field: 'irrigation_frequency', resizable: true },
        { headerName: 'Μέθοδος', field: 'irrigation_method', resizable: true },
        { headerName: 'Ποσότητα', field: 'quantity', resizable: true },
        { headerName: 'Δράση', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ];

      this.fertilisationColumnDefs = [
        { headerName: 'Αγροτεμάχιο', field: 'plot_code', resizable: true },
        { headerName: 'Γεωπόνος', field: 'agronomist', resizable: true },
        { headerName: 'Χειριστής', field: 'operator', resizable: true },
        { headerName: 'Αρ.Συνταγής', field: 'batch_number', resizable: true },
        { headerName: 'Εμπ.Όνομα', field: 'commercial_name', resizable: true },
        { headerName: 'Xημ.Σύσταση', field: 'chemical_composition', resizable: true },
        { headerName: 'Ημερομηνία', field: 'datetime', resizable: true },
        { headerName: 'Δοσολογία', field: 'dosage', resizable: true },
        { headerName: 'Μον.', field: 'dosage_measurement_units', resizable: true },
        { headerName: 'Ανά', field: 'dosage_applied_by', resizable: true },
        { headerName: 'Ποσότητα', field: 'amount_applied', resizable: true },
        { headerName: 'Μον.', field: 'amount_measurement_units', resizable: true },
        { headerName: 'Καιρικές Συν.', field: 'weather_conditions', resizable: true },
        { headerName: 'Μέθοδος', field: 'implementation_method', resizable: true },
        { headerName: 'Καλ.Στάδιο', field: 'cultivation_stage', resizable: true },
        { headerName: 'Δράση', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ];

      this.sprayingColumnDefs = [
        { headerName: 'Αγροτεμάχιο', field: 'plot_code', resizable: true },
        { headerName: 'Χειριστής', field: 'operator', resizable: true },
        { headerName: 'Αρ.Συνταγής', field: 'batch_number', resizable: true },
        { headerName: 'Εμπ.Όνομα', field: 'commercial_name', resizable: true },
        { headerName: 'Δρ.Ουσία', field: 'active_substance', resizable: true },
        { headerName: 'Ημερομηνία', field: 'datetime', resizable: true },
        { headerName: 'Δοσολογία', field: 'dosage', resizable: true },
        { headerName: 'Μον.', field: 'dosage_measurement_units', resizable: true },
        { headerName: 'Ανά', field: 'dosage_applied_by', resizable: true },
        { headerName: 'Ποσ.Διαλύματος', field: 'amount_of_solution', resizable: true },
        { headerName: 'Μον.', field: 'amount_measurement_units', resizable: true },
        { headerName: 'Ανά', field: 'amount_of_solution_applied_by', resizable: true },
        { headerName: 'Συν.Ποσότητα', field: 'amount_applied', resizable: true },
        { headerName: 'Μον.', field: 'amount_measurement_units', resizable: true },
        { headerName: 'Στάδιο', field: 'cultivation_stage', resizable: true },
        { headerName: 'Αιτιολόγηση', field: 'justification', resizable: true },
        { headerName: 'PHI', field: 'phi', resizable: true },
        { headerName: 'Τεμάχια', field: 'amount_of_item', resizable: true },
        { headerName: 'Δράση', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ];

      this.harvestColumnDefs = [
        { headerName: 'Αγροτεμάχιο', field: 'plot_code', resizable: true },
        { headerName: 'Καθαρισμός Εξοπλισμού', field: 'cleaning_of_harvesting_equipment', resizable: true },
        { headerName: 'Πελάτης', field: 'client', resizable: true },
        { headerName: 'Ημερομηνία', field: 'datetime', resizable: true },
        { headerName: 'Αριθμός Παρτίδας', field: 'lot_number', resizable: true },
        { headerName: 'Σύνολο', field: 'summ', resizable: true },
        { headerName: 'Δράση', cellRenderer: this.deleteButtonRenderer, maxWidth: 100 }
      ];

    }
  }

  deleteButtonRenderer = (params: any) => {
    let eGui = document.createElement('div');
    eGui.innerHTML = `<button class="action-button text-md text-red-500 hover:text-red-600" data-action="delete">
    <i class="far fa-trash-alt"></i>
    </button>`;

    eGui.querySelectorAll('.action-button').forEach(button => {
      button.addEventListener('click', () => {
        const action = button.getAttribute('data-action');
        const paramsId = params.data.id;
        if (action === 'delete') {
          this.deleteRecord(paramsId)
        }
      });
    });
    return eGui;
  }

  deleteRecord(paramsId: any) {
    const title = 'HISTORY.DELETE_TITLE';
    const yesDelete = 'PRODUCT.YES_DELETE';
    const deletedTitle = 'PRODUCT.DELETED_TITLE';
    const deletedText = 'HISTORY.DELETED_TEXT';
    const cancel = 'PRODUCT.CANCEL';

    this.translate.get([title, yesDelete, yesDelete, cancel, deletedTitle, deletedText])
      .subscribe(translations => {
        Swal.fire({
          title: translations[title],
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: 'rgb(147, 163, 160)',
          confirmButtonText: translations[yesDelete],
          cancelButtonText: translations[cancel],
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          },
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: translations[deletedTitle],
              text: translations[deletedText],
              icon: 'success',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000
            });

            if (this.isIrrigation) {
              this.req.deleteHistoricalCrop('irrigation', paramsId).subscribe(res => {
                this.getIrrigationData();
              })
            }

            else if (this.isFertilisation) {
              this.req.deleteHistoricalCrop('fertilisation', paramsId).subscribe(res => {
                this.getFertilisationData();
              })
            }

            else if (this.isSpraying) {
              this.req.deleteHistoricalCrop('spraying', paramsId).subscribe(res => {
                this.getSprayingData();
              })
            }

            else if (this.isHarvest) {
              this.req.deleteHistoricalCrop('harvest', paramsId).subscribe(res => {
                this.getHarvestData();
              })
            }
          }
        });
      });
  }

  textList() {
    this.dateRange = '';
    if (this.plotChoose.length == 0) {
      this.noData()
    } else if (this.plotChoose.length > 0) {
      this.plotChoose.forEach((el: any) => {
        this.elArray.push('crop_info_id=' + el.crop_info_id + '&')
        this.splitArray = (('?' + this.elArray).replace(/,/g, '').slice(0, -1));
      });
      this.dateRange = this.splitArray + '&start_date=' + this.startingDateString + '&end_date=' + this.endingDateString
    }
  }

  noData() {
    this.irrigationData = [];
    this.fertilisationData = [];
    this.sprayingData = [];
    this.harvestData = [];
    this.elArray = [];
    this.splitArray = ''
    const irrigationQuantity = 0
    const irrigationHours = 0
    this.irrigationQuantity = 0
    this.irrigationHours = 0
    this.irrigationData = 0
    this.fertilisationDossage = 0
    this.fertilisationAmount = 0
  }

  getAllIrrigation() {
    this.req.getIrrigations().subscribe((res) => {
      this.irrigationData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          plot_code: `${element.plot_code} - ${this.cropType}`,
          datetime: this.datePipe.transform(element.datetime, 'dd-MMMM-yyyy hh:mm'),
          hours: element.hours.toFixed(2),
          irrigation_frequency: element.irrigation_frequency,
          irrigation_method: element.irrigation_method,
          quantity: element.quantity.toFixed(3)
        }
      });
      const irrigationQuantity: number[] = this.irrigationData.map((obj: any) => parseFloat(obj.quantity));
      const irrigationHours: number[] = this.irrigationData.map((obj: any) => parseFloat(obj.hours))
      this.irrigationQuantity = irrigationQuantity.length > 0 ? irrigationQuantity.reduce((sum, quantity) => sum + quantity) : 0;
      this.irrigationHours = irrigationHours.length > 0 ? irrigationHours.reduce((sum, hours) => sum + hours) : 0;
      this.irrigationData = [...this.irrigationData]
    });
  }

  getIrrigationData() {
    this.irrigationData = []
    const irrigationQuantity = 0
    const irrigationHours = 0
    this.irrigationQuantity = 0
    this.irrigationHours = 0
    this.irrigationData = 0
    this.splitArray = [];
    this.elArray = [];
    this.textList();
    this.req.getPlotIrrigationHistory(this.dateRange).subscribe((res) => {
      this.irrigationData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          id: element._id,
          plot_code: `${element.plot_code} - ${this.cropType}`,
          datetime: this.datePipe.transform(element.datetime, 'dd-MMMM-yyyy hh:mm'),
          hours: element.hours.toFixed(2),
          irrigation_frequency: element.irrigation_frequency,
          irrigation_method: element.irrigation_method,
          quantity: element.quantity.toFixed(3)
        }
      });
      const irrigationQuantity: number[] = this.irrigationData.map((obj: any) => parseFloat(obj.quantity));
      const irrigationHours: number[] = this.irrigationData.map((obj: any) => parseFloat(obj.hours))
      this.irrigationQuantity = irrigationQuantity.length > 0 ? irrigationQuantity.reduce((sum, quantity) => sum + quantity) : 0;
      this.irrigationHours = irrigationHours.length > 0 ? irrigationHours.reduce((sum, hours) => sum + hours) : 0;
    });
  }

  getAllFertilisation() {
    this.splitArray = [];
    this.elArray = [];
    this.fertilisationDossage = 0
    this.fertilisationAmount = 0
    this.fertilisationAmount = 0
    this.req.getFertilisation().subscribe((res) => {
      this.fertilisationData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          plot_code: `${element.plot_code} - ${this.cropType}`,
          agronomist: element.agronomist,
          batch_number: element.batch_number,
          commercial_name: element.commercial_name,
          chemical_composition: element.chemical_composition,
          datetime: this.datePipe.transform(element.starting_date, 'dd-MMMM-yyyy hh:mm'),
          dosage: element.dosage,
          dosage_measurement_units: element.dosage_measurement_units,
          dosage_applied_by: element.dosage_applied_by,
          operator: element.operator,
          implementation_method: element.implementation_method,
          cultivation_stage: element.cultivation_stage,
          weather_conditions: element.weather_conditions,
          amount_applied: element.amount_applied.toFixed(3),
          amount_measurement_units: element.amount_measurement_units
        }
      });
      const fertilisationDossage = this.fertilisationData.map((obj: any) => obj.dosage);
      this.fertilisationDossage = fertilisationDossage.length > 0 ? fertilisationDossage.reduce((sum: any, dossage: any) => sum + dossage) : 0
      const fertilisationAmount = this.fertilisationData.map((obj: any) => parseFloat(obj.amount_applied))
      this.fertilisationAmount = fertilisationAmount.length > 0 ? fertilisationAmount.reduce((sum: any, amount: any) => sum + amount) : 0
    });
  }

  getFertilisationData() {
    this.splitArray = [];
    this.elArray = [];
    this.fertilisationDossage = 0
    this.fertilisationAmount = 0
    this.fertilisationAmount = 0
    this.textList()
    this.req.getPlotFertilisationHistory(this.dateRange).subscribe((res) => {
      this.fertilisationData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          id: element._id,
          plot_code: `${element.plot_code} - ${this.cropType}`,
          agronomist: element.agronomist,
          batch_number: element.batch_number,
          commercial_name: element.commercial_name,
          chemical_composition: element.chemical_composition,
          datetime: this.datePipe.transform(element.starting_date, 'dd-MMMM-yyyy hh:mm'),
          dosage: element.dosage,
          dosage_measurement_units: element.dosage_measurement_units,
          dosage_applied_by: element.dosage_applied_by,
          operator: element.operator,
          implementation_method: element.implementation_method,
          cultivation_stage: element.cultivation_stage,
          weather_conditions: element.weather_conditions,
          amount_applied: element.amount_applied.toFixed(3),
          amount_measurement_units: element.amount_measurement_units
        }
      });
      const fertilisationDossage = this.fertilisationData.map((obj: any) => obj.dosage);
      this.fertilisationDossage = fertilisationDossage.length > 0 ? fertilisationDossage.reduce((sum: any, dossage: any) => sum + dossage) : 0
      const fertilisationAmount = this.fertilisationData.map((obj: any) => parseFloat(obj.amount_applied))
      this.fertilisationAmount = fertilisationAmount.length > 0 ? fertilisationAmount.reduce((sum: any, amount: any) => sum + amount) : 0
    });
  }

  getSprayingData() {
    this.splitArray = [];
    this.elArray = [];
    this.textList()
    this.req.getPlotSprayingHistory(this.dateRange).subscribe((res) => {
      this.sprayingData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          id: element._id,
          plot_code: `${element.plot_code} - ${this.cropType}`,
          agronomist: element.agronomist,
          batch_number: element.batch_number,
          active_substance: element.active_substance,
          amount_of_solution: element.amount_of_solution,
          amount_of_solution_measurement_units: element.amount_of_solution_measurement_units,
          amount_of_solution_applied_by: element.amount_of_solution_applied_by,
          amount_applied: element.amount_applied,
          amount_measurement_units: element.amount_measurement_units,
          commercial_name: element.commercial_name,
          operator: element.operator,
          cultivation_stage: element.cultivation_stage,
          implementation_method: element.implementation_method,
          justification: element.justification,
          datetime: this.datePipe.transform(element.datetime, 'dd-MMMM-yyyy hh:mm'),
          hours: element.hours,
          phi: element.phi,
          dosage: element.dosage,
          dosage_applied_by: element.dosage_applied_by,
          dosage_measurement_units: element.dosage_measurement_units,
          weather_conditions: element.weather_conditions,
          amount_of_item: element.amount_of_item
        }
      });
      const sprayingDosage = this.sprayingData.map((obj: any) => obj.dosage)
      this.sprayingDosage = sprayingDosage.length > 0 ? sprayingDosage.reduce((sum: any, dosage: any) => sum + dosage) : 0;
      const sprayingAmount = this.sprayingData.map((obj: any) => obj.amount_applied)
      this.sprayingAmount = sprayingAmount.length > 0 ? sprayingAmount.reduce((sum: any, amount: any) => sum + amount) : 0;
      const sprayingSolution = this.sprayingData.map((obj: any) => obj.amount_of_solution)
      this.sprayingSolution = sprayingSolution.length > 0 ? sprayingSolution.reduce((sum: any, solution: any) => sum + solution) : 0;
    });
  }

  getAllSprayingData() {
    this.req.getSpraying().subscribe((res) => {
      this.sprayingData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          plot_code: `${element.plot_code} - ${this.cropType}`,
          agronomist: element.agronomist,
          batch_number: element.batch_number,
          active_substance: element.active_substance,
          amount_of_solution: element.amount_of_solution,
          amount_of_solution_measurement_units: element.amount_of_solution_measurement_units,
          amount_of_solution_applied_by: element.amount_of_solution_applied_by,
          amount_applied: element.amount_applied,
          amount_measurement_units: element.amount_measurement_units,
          commercial_name: element.commercial_name,
          operator: element.operator,
          cultivation_stage: element.cultivation_stage,
          implementation_method: element.implementation_method,
          justification: element.justification,
          datetime: this.datePipe.transform(element.datetime, 'dd-MMMM-yyyy hh:mm'),
          hours: element.hours,
          phi: element.phi,
          dosage: element.dosage,
          dosage_applied_by: element.dosage_applied_by,
          dosage_measurement_units: element.dosage_measurement_units,
          weather_conditions: element.weather_conditions,
          amount_of_item: element.amount_of_item,
        }
      });
      const sprayingDosage = this.sprayingData.map((obj: any) => obj.dosage)
      this.sprayingDosage = sprayingDosage.length > 0 ? sprayingDosage.reduce((sum: any, dosage: any) => sum + dosage) : 0;
      const sprayingAmount = this.sprayingData.map((obj: any) => obj.amount_applied)
      this.sprayingAmount = sprayingAmount.length > 0 ? sprayingAmount.reduce((sum: any, amount: any) => sum + amount) : 0;
      const sprayingSolution = this.sprayingData.map((obj: any) => obj.amount_of_solution)
      this.sprayingSolution = sprayingSolution.length > 0 ? sprayingSolution.reduce((sum: any, solution: any) => sum + solution) : 0;
    });
  }

  getHarvestData() {
    this.splitArray = [];
    this.elArray = [];
    this.textList()
    this.req.getPlotHarvestHistory(this.dateRange).subscribe((res) => {
      this.harvestData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          id: element._id,
          plot_code: `${element.plot_code} - ${this.cropType}`,
          cleaning_of_harvesting_equipment: element.cleaning_of_harvesting_equipment,
          client: element.client,
          datetime: element.datetime,
          lot_number: element.lot_number,
          summ: element.summ.toFixed(2),
        }
      });
      const harvesSum = this.harvestData.map((obj: any) => obj.summ)
      this.harvestSum = harvesSum.length > 0 ? harvesSum.reduce((sum: any, sumharvest: any) => sum + sumharvest) : 0
    });
  }

  getAllHarvestData() {
    this.req.getHarvest().subscribe((res) => {
      this.harvestData = res.map(element => {
        this.plotCodes.forEach((el: any) => {
          if (el.crop_info_id == element.crop_info_id) {
            this.cropType = el.crop_type
          }
        });
        return {
          plot_code: `${element.plot_code} - ${this.cropType}`,
          cleaning_of_harvesting_equipment: element.cleaning_of_harvesting_equipment,
          client: element.client,
          datetime: element.datetime,
          lot_number: element.lot_number,
          summ: element.summ
        }
      });
      const harvesSum = this.harvestData.map((obj: any) => obj.summ)
      this.harvestSum = harvesSum.length > 0 ? harvesSum.reduce((sum: any, sumharvest: any) => sum + sumharvest) : 0
    });
  }

  irrigationMapped() {
    this.irrigationDataMap = this.irrigationData.map((el: any) => [
      el.plot_code,
      el.datetime,
      el.hours,
      el.irrigation_frequency,
      el.irrigation_method,
      el.irrigation_method
    ]);
  }

  fertilMapped() {
    this.fertilisationDataMap = this.fertilisationData.map((el: any) => [
      el.plot_code,
      el.agronomist,
      el.batch_number,
      el.commercial_name,
      el.chemical_composition,
      el.datetime,
      el.dosage,
      el.dosage_measurement_units,
      el.dosage_applied_by,
      el.operator,
      el.implementation_method,
      el.cultivation_stage,
      el.weather_conditions,
      el.amount_applied,
      el.amount_measurement_units
    ]);
  }

  sprayMapped() {
    this.sprayingDataMap = this.sprayingData.map((el: any) => [
      el.plot_code,
      el.agronomist,
      el.batch_number,
      el.active_substance,
      el.amount_of_solution,
      el.amount_of_solution_measurement_units,
      el.amount_of_solution_applied_by,
      el.amount_applied,
      el.amount_measurement_units,
      el.commercial_name,
      el.operator,
      el.cultivation_stage,
      el.implementation_method,
      el.justification,
      el.datetime,
      el.hours,
      el.phi,
      el.dosage,
      el.dosage_measurement_units,
      el.dosage_applied_by,
      el.weather_conditions,
      el.amount_of_item
    ]);
  }

  harvestMapped() {
    this.harvestDataMap = this.harvestData.map((el: any) => [
      el.plot_code,
      el.cleaning_of_harvesting_equipment,
      el.client,
      el.datetime,
      el.lot_number,
      el.summ
    ]);
  }

  exportCsvIrrigation() {
    this.irrigationMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    if (this.langStatus.includes('en')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers: ['Plot', 'Date', 'Hours', 'Frequency', 'Method', 'Quantity'],
      };
      new ngxCsv(this.irrigationData, 'Irrigation_' + dateTime, options);
    } else if (this.langStatus.includes('gr')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers: ['Αργοτεμάχιο', 'Ημερομηνία', 'Ώρες', 'Συχνότητα', 'Μέθοδος', 'Ποσότητα'],
      };
      new ngxCsv(this.irrigationDataMap, 'Άρδρευσης_' + dateTime, options);
    }
  }

  exportExcelIrrigation() {
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    const workbook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = {};
    this.irrigationMapped()
    if (this.langStatus.includes('en')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Name', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Last Name', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Vat Number', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Plot', 'Date', 'Hours', 'Frequency', 'Method', 'Quantity']
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.irrigationDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Irrigation_' + dateTime + '.xlsx')
    } else if (this.langStatus.includes('gr')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Όνομα', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Επίθετο', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['ΑΦΜ', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Αργοτεμάχιο', 'Ημερομηνία', 'Ώρες', 'Συχνότητα', 'Μέθοδος', 'Ποσότητα']
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.irrigationDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Άρδρευσης_' + dateTime + '.xlsx')
    }
  }

  exportPdfIrrigation() {
    this.irrigationMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    this.IrrigationSum = []
    this.IrrigationSum.push(this.irrigationQuantity, this.irrigationHours)
    if (this.langStatus.includes('en')) {
      this.headColumns = ['Plot', 'Date', 'Hours', 'Frequency', 'Method', 'Quantity']
      this.headColumnsSum = ['Total Quantity', 'Total Hours']
      this.SumTable = [{
        Total_Quantity: this.IrrigationSum[0],
        Total_Hours: this.IrrigationSum[1]
      }]
    } else if (this.langStatus.includes('gr')) {
      this.headColumns = ['Αργοτεμάχιο', 'Ημερομηνία', 'Ώρες', 'Συχνότητα', 'Μέθοδος', 'Ποσότητα']
      this.headColumnsSum = ['Συνολική Ποσότητα', 'Σύνολικές Ώρες']
      this.SumTable = [{
        Συνολική_Ποσότητα: this.IrrigationSum[0],
        Σύνολικές_Ώρες: this.IrrigationSum[1]
      }]
    }
    const doc = new jsPDF();
    doc.addFileToVFS('LiberationSerif-Regular-normal.ttf', font2);
    doc.addFont('LiberationSerif-Regular-normal.ttf', 'LiberationSerif-Regular', 'normal');
    doc.setFont('LiberationSerif-Regular', 'normal');
    const valuesArray2 = this.SumTable.map((obj: any) => Object.values(obj));
    doc.setFontSize(12)
    doc.setTextColor('#5e797e')
    if (this.langStatus.includes('en')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('Vat Number: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Crop Management / Irrigation ', 150, 10)
    } else if (this.langStatus.includes('gr')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('ΑΦΜ: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Καλλιεργητικές Φροντίδες / Άρδρευση ', 140, 10)
    }
    doc.addImage("./assets/logo.png", 'PNG', 90, 10, 30, 20);
    autoTable(doc, {
      theme: 'grid',
      head: [this.headColumns],
      styles: { font: "LiberationSerif-Regular", fontSize: 9 },
      body: this.irrigationDataMap,
      startY: 45,
    });
    autoTable(doc, {
      theme: 'grid',
      head: [this.headColumnsSum],
      styles: { font: "LiberationSerif-Regular", fontSize: 9 },
      body: valuesArray2,
      startY: 250,
      margin: { left: 120 },
    });
    doc.addImage("./assets/logo-wings.png", 'PNG', 178, 280, 20, 10);
    if (this.langStatus.includes('en')) {
      doc.save("Irrigation_" + dateTime + ".pdf");
    } else if (this.langStatus.includes('gr')) {
      doc.save("Άρδρευση_" + dateTime + ".pdf");
    }
  }

  exportCsvFertil() {
    this.fertilMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    if (this.langStatus.includes('en')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers:
          ['Plot', 'Agronomist', 'Batch Number', 'Commercial Name', 'Chemical Composition', 'Date',
            'Dosage',
            'Dosage Measurement Units',
            'Dosage Applied By',
            'Operator',
            'Implementation Method',
            'Cultivation Stage',
            'Weather Conditions',
            'Amount Applied',
            'Amount Measurement Units']
      };
      new ngxCsv(this.fertilisationDataMap, 'Fertilisation_' + dateTime, options);
    } else if (this.langStatus.includes('gr')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers: ['Αγροτεμάχιο', 'Γεωπόνος', 'Αρ_Συνταγής', 'Εμπ_Όνομα', 'Χημική_Σύσταση', 'Ημερομηνία', 'Δοσολογία',
          'Δοσ_Μονάδα', 'Δοσολογία_Ανά', 'Χειριστής', 'Μέθοδος', 'Στάδιο', 'Καιρός', 'Ποσ_Διαλύματος', 'Μονάδα_Μέτρησης']
      };
      new ngxCsv(this.fertilisationDataMap, 'Λίπανσης_' + dateTime, options);
    }
  }

  exportExcelFertil() {
    this.fertilMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    const workbook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = {};
    if (this.langStatus.includes('en')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Name', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Last Name', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Vat Number', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Plot', 'Agronomist', 'Batch Number', 'Commercial Name', 'Chemical Composition', 'Date',
        'Dosage', 'Dosage Measurement Units', 'Dosage Applied By', 'Operator', 'Implementation Method', 'Cultivation Stage',
        'Weather Conditions', 'Amount Applied', 'Amount Measurement Units']
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.fertilisationDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Fertilisation_' + dateTime + '.xlsx')
    } else if (this.langStatus.includes('gr')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Όνομα', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Επίθετο', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['ΑΦΜ', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Αγροτεμάχιο', 'Γεωπόνος', 'Αρ_Συνταγής', 'Εμπ_Όνομα', 'Χημική_Σύσταση', 'Ημερομηνία', 'Δοσολογία',
        'Δοσ_Μονάδα', 'Δοσολογία_Ανά', 'Χειριστής', 'Μέθοδος', 'Στάδιο', 'Καιρός', 'Ποσ_Διαλύματος', 'Μονάδα_Μέτρησης']
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.fertilisationDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Λίπανσης_' + dateTime + '.xlsx')
    }
  }

  exportPdfFertil() {
    this.fertilMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    const afmFarmer = this.afmFarmer
    let sumAmount = this.fertilisationAmount.toFixed(2)
    this.FertilisationSum = []
    this.FertilisationSum.push(sumAmount, this.fertilisationDossage)
    if (this.langStatus.includes('en')) {
      this.headColumns = ['Plot', 'Agronomist', 'Batch Number', 'Commercial Name', 'Chemical Composition',
        'Date', 'Dosage', 'Dosage Measurement Units', 'Dosage Applied By', 'Operator', 'Implementation Method', 'Cultivation Stage',
        'Weather Conditions', 'Amount Applied', 'Amount Measurement Units']
      this.headColumnsSum = ['Total Amount']
      this.SumTable = [{
        Total_Amount: this.FertilisationSum[0],
      }]
    } else if (this.langStatus.includes('gr')) {
      this.headColumns = [
        'Αγροτεμάχιο', 'Γεωπόνος', 'Αρ_Συνταγής', 'Εμπ_Όνομα', 'Χημική_Σύσταση', 'Ημερομηνία', 'Δοσολογία',
        'Δοσ_Μονάδα', 'Δοσολογία_Ανά', 'Χειριστής', 'Μέθοδος', 'Στάδιο', 'Καιρός', 'Ποσ_Διαλύματος', 'Μονάδα_Μέτρησης'
      ]
      this.headColumnsSum = ['Συνολικό Ποσό']
      this.SumTable = [{
        Total_Amount: this.FertilisationSum[0],
      }]
    }
    const doc = new jsPDF({
      orientation: 'landscape'
    });
    doc.addFileToVFS('LiberationSerif-Regular-normal.ttf', font2);
    doc.addFont('LiberationSerif-Regular-normal.ttf', 'LiberationSerif-Regular', 'normal');
    doc.setFont('LiberationSerif-Regular', 'normal');
    doc.setFontSize(12)
    doc.setTextColor('#5e797e')
    if (this.langStatus.includes('en')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('Vat Number: ' + this.afmFarmer, 10, 20)
      doc.text('Crop Management / Fertilisation ', 220, 10)
    } else if (this.langStatus.includes('gr')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('ΑΦΜ: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Καλλιεργητικές Φροντίδες / Λίπανση ', 220, 10)
    }
    doc.addImage("./assets/logo.png", 'PNG', 130, 10, 30, 20);
    autoTable(doc, {
      theme: 'grid',
      head: [this.headColumns],
      styles: { font: "LiberationSerif-Regular", fontSize: 9 },
      body: this.fertilisationDataMap,
      startY: 45,
    });
    doc.addImage("./assets/logo-wings.png", 'PNG', 250, 198, 20, 10);
    if (this.langStatus.includes('en')) {
      doc.save("Fertilisation_" + dateTime + ".pdf");
      doc.addPage();
    } else if (this.langStatus.includes('gr')) {
      doc.save("Λίπανση_" + dateTime + ".pdf");
      doc.addPage();
    }
  }

  exportCsvSpraying() {
    this.sprayMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    if (this.langStatus.includes('en')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers: ['Plot', 'Agronomist', 'Batch', 'Substance', 'Solution', 'Sol.Units', 'By', 'Amount', 'Measur. Units',
          'Com.Name', 'Operator', 'Stage', 'Method', 'Justification', 'Date', 'Hours', 'Phi', 'Dosage', 'By', 'D.Units',
          'Weather', 'Number_of_Items'
        ],
      };
      new ngxCsv(this.sprayingDataMap, 'Spraying_' + dateTime, options);
    } else if (this.langStatus.includes('gr')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers: ['Αγροτεμάχιο', 'Γεωπόνος', 'Αρ.Συνταγής', 'Δραστική Ουσία', 'Διάλυμα', 'Δρ.Μονάδα', 'Ανά', 'Ποσ.Διαλύματος', 'Μονάδα Μέτρησης',
          'Εμπ.Όνομα', 'Χειριστής', 'Καλλ.Στάδιο', 'Μέθοδος', 'Αιτιολόγηση', 'Ημερ/νια', 'Ώρες', 'Phi', 'Δοσολογία', 'Ανά', 'Δοσ.Μονάδα',
          'Καιρός', 'Αριθμός_Σκευσμάτων'
        ],
      };
      new ngxCsv(this.sprayingDataMap, 'Φυτοπροστασίας_' + dateTime, options);
    }
  }

  exportExcelSpray() {
    this.sprayMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    const workbook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = {};
    if (this.langStatus.includes('en')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Name', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Last Name', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Vat Number', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Plot', 'Agronomist', 'Batch', 'Substance', 'Solution', 'Sol.Units', 'By', 'Amount', 'Measur. Units',
        'Com.Name', 'Operator', 'Stage', 'Method', 'Justification', 'Date', 'Hours', 'Phi', 'Dosage', 'By', 'D.Units',
        'Weather', 'Number_of_Items'
      ]
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.sprayingDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Spraying_' + dateTime + '.xlsx')
    } else if (this.langStatus.includes('gr')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Όνομα', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Επίθετο', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['ΑΦΜ', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Αγροτεμάχιο', 'Γεωπόνος', 'Αρ_Συνταγής', 'Δραστική_Ουσία', 'Διάλυμα', 'Δρ_Μονάδα', 'Ανά', 'Ποσ_Διαλύματος', 'Μονάδα_Μέτρησης',
        'Εμπ_Όνομα', 'Χειριστής', 'Καλλ_Στάδιο', 'Μέθοδος', 'Αιτιολόγηση', 'Ημερομηνία', 'Ώρες', 'Phi', 'Δοσολογία', 'Ανά', 'Δοσ_Μονάδα',
        'Καιρός', 'Αριθμός_Σκευσμάτων'
      ]
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.sprayingDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Φυτοπροστασίας_' + dateTime + '.xlsx')
    }
  }

  exportPdfSpray() {
    this.sprayMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    const afmFarmer = this.afmFarmer
    let sumAmount = this.sprayingAmount.toFixed(2)
    this.SpraySum = []
    this.SpraySum.push(sumAmount, this.sprayingDosage)
    if (this.langStatus.includes('en')) {
      this.headColumns = [
        'Plot', 'Agronomist', 'Batch', 'Substance', 'Solution', 'Sol.Units', 'By', 'Amount', 'Measur. Units',
        'Com.Name', 'Operator', 'Stage', 'Method', 'Justification', 'Date', 'Hours', 'Phi', 'Dosage', 'By', 'D.Units', 'Weather', 'Number_of_Items'
      ]
      this.headColumnsSum = ['Total Amount']
      this.SumTable = [{
        Total_Amount: this.SpraySum[0]
      }]
    }
    else if (this.langStatus.includes('gr')) {
      this.headColumns = [
        'Αγροτεμάχιο', 'Γεωπόνος', 'Αρ_Συνταγής', 'Δραστική_Ουσία', 'Διάλυμα', 'Μονάδα',
        'Ανά', 'Ποσότητα Διαλύματος', 'Μονάδα', 'Εμπορικό_Όνομα', 'Χειριστής', 'Καλλ_Στάδιο',
        'Μέθοδος', 'Αιτιολόγηση', 'Ημερομηνία', 'Ώρες', 'Phi', 'Δοσολογία', 'Ανά', 'Μονάδα', 'Καιρός', 'Αριθμός_Σκευσμάτων'
      ]
      this.headColumnsSum = ['Συνολικό Ποσό']
      this.SumTable = [{
        Total_Amount: this.SpraySum[0]
      }]
    }
    const doc = new jsPDF({
      orientation: 'landscape'
    });
    doc.addFileToVFS('LiberationSerif-Regular-normal.ttf', font2);
    doc.addFont('LiberationSerif-Regular-normal.ttf', 'LiberationSerif-Regular', 'normal');
    doc.setFont('LiberationSerif-Regular', 'normal');
    doc.setFontSize(12)
    doc.setTextColor('#5e797e')
    if (this.langStatus.includes('en')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('Vat Number: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Crop Management / Spraying ', 220, 10)
    } else if (this.langStatus.includes('gr')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('ΑΦΜ: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Καλλιεργητικές Φροντίδες / Φυτοπροστασία ', 220, 10)
    }
    doc.addImage("./assets/logo.png", 'PNG', 130, 10, 30, 20);
    autoTable(doc, {
      theme: 'grid',
      head: [this.headColumns],
      styles: { font: "LiberationSerif-Regular", fontSize: 9 },
      body: this.sprayingDataMap,
      startY: 45,
    });
    doc.addImage("./assets/logo-wings.png", 'PNG', 250, 190, 20, 10);
    if (this.langStatus.includes('en')) {
      doc.save("Spraying" + dateTime + ".pdf");
    } else if (this.langStatus.includes('gr')) {
      doc.save("Φυτοπροστασία" + dateTime + ".pdf");
    }
  }

  exportCsvHarvest() {
    this.harvestMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    if (this.langStatus.includes('en')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers:
          ['Plot', 'Equip_Cleaning', 'Client', 'Date', 'Lot_Number', 'Sum']
      };
      new ngxCsv(this.harvestDataMap, 'Harvest_' + dateTime, options);
    } else if (this.langStatus.includes('gr')) {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        showLabels: true,
        useBom: true,
        headers: ['Αγροτεμάχιο', 'Καθ_Εξοπλισμού', 'Πελάτης', 'Ημερομηνία', 'Αρ_Παρτίδας', 'Άθροισμα']
      };
      new ngxCsv(this.harvestDataMap, 'Συγκομιδή_' + dateTime, options);
    }
  }

  exportExcelHarvest() {
    this.harvestMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    const workbook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = {};
    if (this.langStatus.includes('en')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Name', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Last Name', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Vat Number', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Plot', 'Equipment_Cleaning', 'Client', 'Date', 'Lot_Number', 'Sum']
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.harvestDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Harvest_' + dateTime + '.xlsx')
    } else if (this.langStatus.includes('gr')) {
      XLSX.utils.sheet_add_aoa(worksheet, [['Όνομα', this.firstName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(worksheet, [['Επίθετο', this.lastName]], { origin: 'A2' });
      XLSX.utils.sheet_add_aoa(worksheet, [['ΑΦΜ', this.afmFarmer]], { origin: 'A3' });
      const headers = ['Αγροτεμάχιο', 'Καθαρισμός_Εξοπλισμού', 'Πελάτης', 'Ημερομηνία', 'Αριθμός_Παρτίδας', 'Άθροισμα']
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });
      XLSX.utils.sheet_add_aoa(worksheet, this.harvestDataMap, { origin: 'A6' });
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, 'Συγκομιδή_' + dateTime + '.xlsx')
    }
  }

  exportPdfHarvest() {
    this.harvestMapped()
    const currentDate = new Date();
    const dateTime = this.datePipe.transform(currentDate, 'dd-MM-yyyy')
    this.HarvestSum = []
    this.HarvestSum.push(this.harvestSum)
    if (this.langStatus.includes('en')) {
      this.headColumns = ['Plot', 'Equipment_Cleaning', 'Client', 'Date', 'Lot_Number', 'Sum']
      this.headColumnsSum = ['Total Harvest']
      this.SumTable = [{
        Total_Harvest: this.HarvestSum[0]
      }]
    } else if (this.langStatus.includes('gr')) {
      this.headColumns = ['Αγροτεμάχιο', 'Καθαρισμός_Εξοπλισμού', 'Πελάτης', 'Ημερομηνία', 'Αριθμός_Παρτίδας', 'Άθροισμα']
      this.headColumnsSum = ['Συνολική Συγκομιδή']
      this.SumTable = [{
        Συνολική_Συγκομιδή: this.HarvestSum[0]
      }]
    }
    const doc = new jsPDF();
    doc.addFileToVFS('LiberationSerif-Regular-normal.ttf', font2);
    doc.addFont('LiberationSerif-Regular-normal.ttf', 'LiberationSerif-Regular', 'normal');
    doc.setFont('LiberationSerif-Regular', 'normal');
    doc.setFontSize(12)
    doc.setTextColor('#5e797e')
    if (this.langStatus.includes('en')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('Vat Number: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Crop Management / Harvest ', 150, 10)
    } else if (this.langStatus.includes('gr')) {
      doc.setFontSize(12)
      doc.text(this.firstName, 10, 10)
      doc.text(this.lastName, 10, 15)
      doc.setFontSize(10)
      doc.text('ΑΦΜ: ' + this.afmFarmer, 10, 20)
      doc.setTextColor('#5e797e')
      doc.text('Καλλιεργητικές Φροντίδες / Συγκομιδή ', 140, 10)
    }
    doc.addImage("./assets/logo.png", 'PNG', 90, 10, 30, 20);
    autoTable(doc, {
      theme: 'grid',
      head: [this.headColumns],
      styles: { font: "LiberationSerif-Regular", fontSize: 9 },
      body: this.harvestDataMap,
      startY: 45,
    });
    doc.addImage("./assets/logo-wings.png", 'PNG', 178, 280, 20, 10);
    if (this.langStatus.includes('en')) {
      doc.save("Harvest" + dateTime + ".pdf");
    } else if (this.langStatus.includes('gr')) {
      doc.save("Συγκομιδή" + dateTime + ".pdf");
    }
  }

}