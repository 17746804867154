/// <reference types="@types/googlemaps" />

import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RequestService } from 'src/app/services/request.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Chart, ChartTypeRegistry } from 'chart.js/auto';

declare var google: any;

@Component({
  selector: 'app-single-plot',
  templateUrl: './single-plot.component.html',
  styleUrls: ['./single-plot.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SinglePlotComponent implements OnInit {
  plot: any = [];
  displayedColumns: string[] = ['plot_code', 'municipal_community', 'location', 'regional_unit', 'co_ownership', 'size', 'mapped_background', 'eco_schemes.scheme_code'];
  plotCode: any;
  langStatus: any;
  expandedPanelIndex: number = 0;
  ecoSchemes: any[] = []

  treatmentColumns: string[] = ['lastTreatment', 'treatment', 'start_date', 'end_date'];
  treatmentData: any = [];

  irrigationColumns: string[] = ['lastIrrigation', 'irrigation_method', 'quantity', 'datetime'];
  irrigationData: any = [];

  fertilisationColumns: any[] = ['lastFertilisation', 'chemical_composition', 'amount_applied', 'datetime'];
  fertilisationData: any = [];

  sprayingColumns: any[] = ['lastSpraying', 'commercial_name', 'amount_applied', 'datetime'];
  sprayingData: any = [];

  harvestColumns: any[] = ['lastHarvest', 'cleaning_of_harvesting_equipment', 'summ', 'datetime'];
  harvestData: any = [];

  residuesColumns: any[] = ['lastResidues', 'management_method', 'total_amount', 'datetime'];
  residuesData: any = [];

  devices: any[] = []
  sensors: any[] = [];

  plotDevices: any[] = [];
  buttonStates: { [key: string]: boolean } = {};
  spinner: boolean = false
  @ViewChild('lineCanvas') private lineCanvas!: ElementRef;
  lineChart: any;
  chart!: Chart;
  chartData: any[] = [];
  chartOptions: any = {};
  chartLineData1: any[] = [];
  chartLineData2: any[] = [];
  deviceComponents: any[] = [];
  componentsD: any[] = [];
  deviceID: any;
  lastDeviceComponents: any[] = [];
  phComponentPublic: any[] = [];
  t12ComponentPublic: any[] = [];
  sensorsButtons: boolean = false
  plotDevicesID: any[] = [];
  phFlag: boolean = false
  device_type: string = '';
  buttonStates2: { [key: string]: boolean }[] = [];
  firstDeviceID: string = ''

  constructor(
    private route: ActivatedRoute,
    private req: RequestService<any>,
    public translate: TranslateService,
    private datepipe: DatePipe,) {
  }

  ngOnInit(): void {
    const plotId = this.route.snapshot.paramMap.get('id');
    this.req.getPlot(plotId).subscribe((res) => {
      this.plotDevicesID = res.devices
      this.plot = res;
      this.plotDevices.push(res)
      const defaultId = this.plot.crop_info[0].crop_info_id;
      this.updateTables(defaultId);
      this.ecoSchemes.push(res)
      this.plot.size = this.plot.size.toFixed(1)
      this.plotCode = this.plot.plot_code;
      this.initChart();
      this.initMap();
      this.initValues();
    });
  }

  initMap(): void {
    this.req.getDevices().subscribe(res => {
      const filterArray = res.filter(device => this.plotDevicesID.includes(device.attributes.uuid));
      this.devices = filterArray;
      if (this.plot && this.plot.map_coordinates && Array.isArray(this.plot.map_coordinates) && this.plot.map_coordinates.length > 0) {
        const mapCenter = {
          lat: parseFloat(this.devices[0]?.attributes.lat),
          lng: parseFloat(this.devices[0]?.attributes.lon)
        };
        const map = new google.maps.Map(document.getElementById("map")!, {
          zoom: 14,
          center: mapCenter,
          mapTypeId: google.maps.MapTypeId.SATELLITE,
          mapTypeControl: false,
          streetViewControl: false
        });
        const pathCoordinates: google.maps.LatLngLiteral[] = this.plot.map_coordinates.map((coord: any) => ({
          lat: +coord.lat,
          lng: +coord.lon
        }));
        if (this.plot.map_coordinates.length >= 3) {
          const firstNode = this.plot.map_coordinates[0];
          pathCoordinates.push({ lat: +firstNode.lat, lng: +firstNode.lon });
        }
        const plotPolygon = new google.maps.Polygon({
          paths: pathCoordinates,
          geodesic: true,
          strokeColor: "rgb(150, 231, 178)",
          strokeOpacity: 1.0,
          fillOpacity: 0.35,
          strokeWeight: 2,
          fillColor: "#FFC107",
        });
        this.sensors = this.devices.map(device => device.attributes.components.map((component: any, index: any) => ({
          lat: parseFloat(device.attributes.lat),
          lng: parseFloat(device.attributes.lon),
          message: `
                    <div class="info-window-content" style="overflow-x: hidden; overflow-y: hidden; min-width: 280px; min-height:100px">
                        <button id="closeInfoWindow" style="position: absolute; top: 5px; right: 5px; font-weight: 600;color: #626f82;">X</button>
                        <h3 id="mapTitle" style="font-size: small;font-weight: 600;color: #626f82;margin-top: -3px;">${device.attributes.name}</h3>
                        <mat-list>
                            <div mat-line><span id="mapTitle">Status:</span> <span id="mapKeys">${device.attributes.status ? 'Active' : 'Inactive'}</span></div>
                            <mat-list-item>
                                <div mat-line><span id="mapTitle">Location:</span> <span id="mapKeys">${this.plot.location}</span></div>
                            </mat-list-item>
                            <mat-list-item>
                                <div mat-line><span id="mapTitle">Installed:</span> <span id="mapKeys">${this.datepipe.transform(device.attributes.created_at, 'medium')}</span></div>
                            </mat-list-item>
                        </mat-list>
                        <div class="p-1">
                            <div class="img-fluid rounded-rectangle img-thumbnail" style="display: flex; justify-content: center; align-items: center; font-size: x-small; text-align: center; font-weight: 600; background-color: rgb(191, 248, 211); border: 1px solid rgb(255, 255, 255); box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; width: 51px; border-radius: 4px;">
                                <p id="sensorsTitle">${component.sensor_type}</p>
                            </div>
                        </div>
                    </div>
                `,
          color: 'rgb(150, 231, 178)',
          markerIcon: ''
        }))).flat();
        this.sensors.forEach(sensor => {
          const sensorPolyline = new google.maps.Polyline({
            path: [{ lat: sensor.lat, lng: sensor.lng }, { lat: sensor.lat, lng: sensor.lng }],
            geodesic: true,
            strokeColor: sensor.color,
            strokeOpacity: 2,
            strokeWeight: 25,
            fillColor: sensor.color,
            fillOpacity: 0.35,
          });
          const marker = new google.maps.Marker({
            position: { lat: sensor.lat, lng: sensor.lng },
            map: map,
            icon: sensor.markerIcon,
            title: 'Sensor Marker'
          });
          const infoWindow = new google.maps.InfoWindow({
            content: sensor.message,
            ariaLabel: "Uluru",
          });
          google.maps.event.addListener(infoWindow, 'domready', () => {
            document.getElementById('closeInfoWindow')?.addEventListener('click', () => {
              infoWindow.close();
            });
          });
          marker.addListener('click', () => {
            infoWindow.open(map, marker);
          });
          sensorPolyline.setMap(map);
        });
        plotPolygon.setMap(map);
      }
    });
  }

  calculateCenter(coordinates: { kombos: string | number; lat: string | number; lon: string | number }[]): google.maps.LatLngLiteral {
    let totalLat = 0;
    let totalLon = 0;
    coordinates.forEach(coord => {
      totalLat += +coord.lat;
      totalLon += +coord.lon;
    });
    const averageLat = totalLat / coordinates.length;
    const averageLon = totalLon / coordinates.length;
    return { lat: averageLat, lng: averageLon };
  }

  getColumnLabel(column: string): any {
    switch (column) {
      case 'plot_code':
        return 'HOME.CODE';
      case 'municipal_community':
        return 'HOME.MUNICIPAL';
      case 'location':
        return 'HOME.LOCATION';
      case 'regional_unit':
        return 'HOME.UNIT';
      case 'co_ownership':
        return 'HOME.OWN';
      case 'size':
        return 'HOME.ACRE';
      case 'mapped_background':
        return 'HOME.MAPPED';
      case 'eco_schemes.scheme_code':
        return 'HOME.ECO_SCHEMES';
      default:
        return '';
    }
  }

  getPropertyValue(column: string) {
    return this.plot[column];
  }

  togglePanel(index: number): void {
    if (this.expandedPanelIndex === index) {
      this.expandedPanelIndex = -1;
    } else {
      this.expandedPanelIndex = index;
    }

    const selectedCropInfoId = this.plot.crop_info[index].crop_info_id;
    this.updateTables(selectedCropInfoId)
  }

  updateTables(id: string) {
    this.req.getPlotTreatment(id).subscribe((res) => {
      if (res.length !== 0) {
        const latestTreatment = res[res.length - 1];
        this.treatmentData = [latestTreatment];
      }
    });

    this.req.getPlotIrrigation(id).subscribe((res) => {
      if (res.length !== 0) {
        const latestIrrigation = res[res.length - 1];
        this.irrigationData = [latestIrrigation];
      }
    });

    this.req.getPlotFertilisation(id).subscribe((res) => {
      if (res.length !== 0) {
        const latestFertilisation = res[res.length - 1];
        this.fertilisationData = [latestFertilisation];
      }
    });

    this.req.getPlotSpraying(id).subscribe((res) => {
      if (res.length !== 0) {
        const latestSpraying = res[res.length - 1];
        this.sprayingData = [latestSpraying];
      }
    });

    this.req.getPlotHarvest(id).subscribe((res) => {
      if (res.length !== 0) {
        const latestHarvest = res[res.length - 1];
        this.harvestData = [latestHarvest];
      }
    });

    this.req.getPlotResidues(id).subscribe((res) => {
      if (res.length !== 0) {
        const latestResidues = res[res.length - 1];
        this.residuesData = [latestResidues];
      }
    });
  }

  initChart() {
    this.lineChart = new Chart(this.lineCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'PH',
            data: [],
            borderColor: 'rgba(73, 215, 255)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(99, 216, 248, 0.455)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: '#1f90ec'
          },
          {
            label: 'Temperature °C',
            data: [],
            borderColor: 'rgba(205, 84, 75)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(205, 84, 75, 0.207)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: 'rgba(205, 84, 75)'
          },
          {
            label: 'EC μS/cm',
            data: [],
            borderColor: 'rgba(67, 84, 147)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(67, 84, 147, 0.307)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: 'rgba(67, 84, 147)'
          },
          {
            label: 'Soil Moisuture %',
            data: [],
            borderColor: 'rgba(40, 127, 71)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(40, 127, 71, 0.307)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: 'rgba(40, 127, 71)'
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              // color: 'grey',
              font: {
                size: 8,
                family: 'Arial'
              }
            },
            grid: {
              color: 'lightgray',
              lineWidth: 0.1
            }
          },
          y: {
            display: true,
            ticks: {
              color: 'grey',
              font: {
                size: 8,
                family: 'Arial'
              }
            },
            grid: {
              color: 'lightgray',
              lineWidth: 0.3
            }
          }
        }
      }
    });
  }

  initValues() {
    this.spinner = true;
    const device_type = 'PH';
    this.plotDevices.forEach(plotDevice => {
      this.firstDeviceID = plotDevice.devices[0]
      plotDevice.devices.forEach((device: any, index: any) => {
        this.buttonStates[device] = index === 0;
      });
    });
    this.req.getDevices(this.firstDeviceID).subscribe(res => {
      if (res) {
        this.spinner = false
        this.sensorsButtons = true
        this.phFlag = true
      }
      this.lastDeviceComponents = [...this.phComponentPublic, ...this.t12ComponentPublic];
      this.lineChart.update();
      this.componentsD.push(res)
      this.initSensor()
    });
  }

  initSensor() {
    this.sensorsButtons = true;
    this.device_type = 'PH';
    this.req.getDevicesOverall(this.firstDeviceID, this.device_type).subscribe(res => {
      console.log(res)
      if (res.length > 0) {
        this.spinner = false;
        const lastObject = res.slice(-1)[0];
        this.phComponentPublic = [lastObject];
        this.lastDeviceComponents = [...this.phComponentPublic, ...this.t12ComponentPublic];
        res.forEach(el => {
          this.lineChart.data.labels.push(this.datepipe.transform(el.attributes.measurement_time, 'dd/MM/yyyy HH:mm'));
          this.lineChart.data.datasets[0].data.push(el.attributes.renke[0].value);
        });
      }
      this.lineChart.update();
      this.buttonStates2 = this.componentsD.map(group => {
        let groupButtonStates: { [key: string]: boolean } = {};
        group.attributes.components.forEach((button: any) => {
          if (button.sensor_type === 'PH' && this.device_type === 'PH') {
            groupButtonStates[button.sensor_type] = true;
            button.isOn = true;
          } else {
            groupButtonStates[button.sensor_type] = false;
            button.isOn = false;
          }
        });
        if (!Object.values(groupButtonStates).includes(true)) {
          const firstButton = group.attributes.components[0];
          groupButtonStates[firstButton.sensor_type] = true;
          firstButton.isOn = true;
        }
        return groupButtonStates;
      });
    });
  }

  chooseDevice(event: any, button: any) {
    // this.sensorsButtons = false
    this.device_type = ''
    this.buttonStates2 = [{ ph: false, t12: false }]
    this.spinner = true
    this.buttonStates[button] = event.source._checked;
    this.lineChart.data.datasets[0].data = []
    this.deviceID = button
    this.componentsD = [];
    this.lineChart.data.labels = [];
    this.phComponentPublic = [];
    this.lineChart.data.datasets[0].data = [];

    if (this.buttonStates[button]) {
      this.req.getDevices(button).subscribe(res => {
        if (res) {
          this.spinner = false
          this.sensorsButtons = true
        }
        this.lastDeviceComponents = [...this.phComponentPublic, ...this.t12ComponentPublic];
        this.lineChart.update();
        this.componentsD.push(res)
      });
    } else {
      this.spinner = false
      this.componentsD = [];
      this.lineChart.data.labels = [];
      this.phComponentPublic = [];
      this.lineChart.data.datasets[0].data = [];
      this.lineChart.update();
    }

    Object.keys(this.buttonStates).forEach(key => {
      if (key !== button) {
        this.buttonStates[key] = false;
      }
    });
    this.componentsD = [];
    this.lastDeviceComponents = [];
    this.componentsD = [];
    this.lineChart.data.labels = [];
    this.phComponentPublic = [];
    this.lineChart.data.datasets[0].data = [];
  }

  chooseSensor(event: any, button: any, groupIndex: number, sens: any) {
    this.deviceID = sens.id
    this.deviceComponents = [];
    button.isOn = event.source._checked;
    this.buttonStates2[groupIndex][button.sensor_type] = button.isOn;

    if (!this.phComponentPublic) {
      this.phComponentPublic = [];
    }
    if (!this.t12ComponentPublic) {
      this.t12ComponentPublic = [];
    }

    // Toggle data for PH sensors
    if (event.source._checked && button.sensor_type.includes("PH")) {
      this.spinner = true
      this.lineChart.data.labels = [];
      this.device_type = 'PH';
      this.lineChart.options.scales.y.ticks.min = 0;
      this.lineChart.options.scales.y.ticks.max = 10;
      this.req.getDevicesOverall(this.deviceID, this.device_type).subscribe(res => {
        if (res.length > 0) {
          this.spinner = false
          const lastObject = res.slice(-1)[0]
          this.phComponentPublic = [lastObject];
          this.lastDeviceComponents = [...this.phComponentPublic, ...this.t12ComponentPublic];
          res.forEach(el => {
            this.lineChart.data.labels.push(this.datepipe.transform(el.attributes.measurement_time, 'dd/MM/yyyy HH:mm'));
            this.lineChart.data.datasets[0].data.push(el.attributes.renke[0].value);
          })
        }
        this.lineChart.update();
      });
    } else if (!event.source._checked && button.sensor_type.includes("PH")) {
      this.phComponentPublic = [];
      this.lineChart.data.datasets[0].data = [];
      this.lastDeviceComponents = [...this.phComponentPublic, ...this.t12ComponentPublic];
      this.lineChart.update();
    }

    // Toggle data for T12 sensors
    if (event.source._checked && button.sensor_type.includes("T12")) {
      this.spinner = true
      this.lineChart.data.labels = [];
      const device_type = 'T12';
      this.req.getDevicesOverall(this.deviceID, device_type).subscribe(res => {
        if (res.length > 0) {
          this.spinner = false
          const lastObject = res.slice(-1)[0]
          this.t12ComponentPublic = [lastObject].map(item => {
            const newTerros12 = item.attributes.terros_12.filter((sensor: any) =>
              ["temperature", "bulk_electrical_conductivity", "transformed_mineral_soil"].includes(sensor.name)
            ).map((sensor: any) => {
              let newName, newUnit, newValue;
              switch (sensor.name) {
                case "temperature":
                  newName = "Temperature";
                  newUnit = sensor.unit;
                  newValue = sensor.value;
                  break;
                case "bulk_electrical_conductivity":
                  newName = "EC";
                  newUnit = sensor.unit;
                  newValue = sensor.value;
                  break;
                case "transformed_mineral_soil":
                  newName = "Soil_Moisture";
                  newUnit = "%";
                  newValue = (sensor.value * 100).toFixed(2);
                  break;
              }
              return {
                name: newName,
                unit: newUnit,
                value: newValue
              };
            });
            return {
              ...item,
              attributes: {
                ...item.attributes,
                terros_12: newTerros12
              }
            };
          });
          this.lastDeviceComponents = [...this.phComponentPublic, ...this.t12ComponentPublic];
          res.forEach((item: any) => {
            this.lineChart.data.labels.push(this.datepipe.transform(item.attributes.measurement_time, 'dd/MM/yyyy HH:mm'));
            this.lineChart.data.datasets[1].data.push(item.attributes.terros_12[1].value);
            this.lineChart.data.datasets[2].data.push(item.attributes.terros_12[2].value);
            this.lineChart.data.datasets[3].data.push(item.attributes.terros_12[3].value * 100);
          })
        }
        this.lineChart.update();
      });
    } else if (!event.source._checked && button.sensor_type.includes("T12")) {
      this.t12ComponentPublic = [];
      this.lineChart.data.datasets[1].data = [];
      this.lineChart.data.datasets[2].data = [];
      this.lineChart.data.datasets[3].data = [];
      this.lastDeviceComponents = [...this.phComponentPublic, ...this.t12ComponentPublic];
      this.lineChart.update();
    }
  }
}