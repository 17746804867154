import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/services/request.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Chart } from 'chart.js/auto';

declare var google: any;

@Component({
  selector: 'app-single-meteo',
  templateUrl: './single-meteo.component.html',
  styleUrls: ['./single-meteo.component.scss']
})
export class SingleMeteoComponent implements OnInit, AfterViewInit {

  @ViewChild('lineCanvas') private lineCanvas!: ElementRef;
  station: any = [];
  stationID: string = '';
  selectedTimeRange: string = 'day';
  name: string = '';
  latest: string | null = '';
  items: string[] = [
    'METEO.HUMIDITY', 'METEO.BAR', 'METEO.TEMP', 'METEO.RAIN_24', 'METEO.RAIN_DAILY',
    'METEO.RAIN_RATE', 'METEO.WIND_SPEED', 'METEO.WIND_DIR', 'METEO.WIND_GUST_DIR'
  ];
  itemValues: any = {};
  lineChart: any;
  chart!: Chart;
  loading: boolean = false;
  
  constructor(
    private router: Router,
    private req: RequestService<any>,
    public translate: TranslateService,
    private datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.stationID = this.router.url.split('/station-single/')[1]

    this.req.getSingleStation(this.stationID).subscribe((res) => {
      this.name = res[0].station_name;
      this.initMap()
    })

    this.req.getStationMeteorologicalData(this.stationID).subscribe((res) => {
      this.latest = this.datepipe.transform(res.last_updated, 'medium')
      this.itemValues = {
        'METEO.HUMIDITY': res.hum_out + ' %',
        'METEO.BAR': res.bar_absolute + ' mb',
        'METEO.TEMP': res.temp_out.toFixed(1) + ' °C',
        'METEO.RAIN_24': res.rain_24_hr_mm + ' mm',
        'METEO.RAIN_DAILY': res.rain_day_mm + ' mm',
        'METEO.RAIN_RATE': res.rain_rate_mm + ' mm',
        'METEO.WIND_SPEED': res.wind_speed + ' km/h',
        'METEO.WIND_DIR': res.wind_dir + ' ° ' + this.getWindDirection(res.wind_dir),
        'METEO.WIND_GUST_DIR': res.wind_dir_of_gust_10_min + ' ° ' + this.getWindDirection(res.wind_dir_of_gust_10_min)
      };
    })
  }

  ngAfterViewInit(): void {
    this.initChart();
    this.populateChart();
  }

  getWindDirection(degree: number): string {
    const directions = [
      'N', 'NNE', 'NE', 'ENE',
      'E', 'ESE', 'SE', 'SSE',
      'S', 'SSW', 'SW', 'WSW',
      'W', 'WNW', 'NW', 'NNW'
    ];

    // Each direction spans 360/16 = 22.5 degrees.
    const index = Math.round(degree / 22.5) % 16;

    return directions[index];
  }

  initChart() {
    this.lineChart = new Chart(this.lineCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Air Pressure (mb)',
            data: [],
            borderColor: 'rgba(73, 215, 255)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(99, 216, 248, 0.455)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: '#1f90ec'
          },
          {
            label: 'Temperature (°C)',
            data: [],
            borderColor: 'rgba(255, 99, 132, 0.9)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(255, 159, 160, 0.5)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: 'rgba(255, 99, 132)'
          },
          {
            label: 'Humidity (%)',
            data: [],
            borderColor: 'rgba(255, 159, 64, 0.9)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(255, 205, 86, 0.5)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: '#ff9f40'
          },
          {
            label: 'Rainfall Rate (mm)',
            data: [],
            borderColor: 'rgba(255, 73, 170, 0.922)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(244, 108, 181, 0.307)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: '#f60aee'
          },
          {
            label: 'Rainfall (mm)',
            data: [],
            borderColor: 'rgba(255, 73, 170, 0.922)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(245, 172, 255, 0.553)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: '#f60aee'
          },
          {
            label: 'Wind Speed (km/h)',
            data: [],
            borderColor: 'rgba(67, 160, 71, 0.9)',
            fill: true,
            borderWidth: 1,
            backgroundColor: 'rgba(129, 199, 132, 0.5)',
            tension: 0.4,
            pointBackgroundColor: 'white',
            pointBorderWidth: 1,
            pointBorderColor: '#43a047'
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              font: {
                size: 8,
                family: 'Arial'
              }
            },
            grid: {
              color: 'lightgray',
              lineWidth: 0.1
            }
          },
          y: {
            display: true,
            ticks: {
              color: 'grey',
              font: {
                size: 8,
                family: 'Arial'
              }
            },
            grid: {
              color: 'lightgray',
              lineWidth: 0.3
            }
          }
        }
      }
    });
  }

  initMap(): void {
    this.req.getWeatherStations().subscribe(res => {
      res.forEach(el => {
        if (el.station_id_uuid == this.stationID) {
          this.station.push(el)
        }
      });
      if (this.name && this.station[0].latitude && this.station[0].longitude) {

        const map = new google.maps.Map(document.getElementById("map")!, {
          zoom: 15,
          center: { lat: this.station[0].latitude, lng: this.station[0].longitude },
          mapTypeId: google.maps.MapTypeId.SATELLITE,
          mapTypeControl: false,
          streetViewControl: false
        });


        const marker = new google.maps.Marker({
          position: { lat: this.station[0].latitude, lng: this.station[0].longitude },
          map: map,
          icon: this.station[0].markerIcon,
          title: 'Sensor Marker'
        });

        const infoWindow = new google.maps.InfoWindow({
          content: `<div class="info-window-content" style="overflow-x: hidden; overflow-y: hidden; min-width: 280px; min-height:100px">
            <h3 id="mapTitle" style="font-size: small;font-weight: 600;color: #626f82;margin-top: -3px;" >${this.name}</h3>
            <mat-list>
            <mat-list-item>
              <div mat-line><span id="mapTitle">Status:</span> <span id="mapKeys">${this.station[0].active ? 'Active' : 'Inactive'}</span></div>
            </mat-list-item>
            <mat-list-item>
              <div mat-line><span id="mapTitle">Company Name:</span> <span id="mapKeys">${this.station[0].company_name}</span></div>
            </mat-list-item>
            <mat-list-item>
              <div mat-line><span id="mapTitle">Registered:</span> <span id="mapKeys">${this.datepipe.transform(this.station[0].registered_date)}</span></div>
            </mat-list-item>
          </mat-list>`,
        });

        google.maps.event.addListener(infoWindow, 'domready', () => {
          document.getElementById('closeInfoWindow')?.addEventListener('click', () => {
            infoWindow.close();
          });
        });
        marker.addListener('click', () => {
          infoWindow.open(map, marker);
        });
      }
    })
  }

  populateChart() {
    this.loading = true;
    this.req.getStationHistoricalData(this.stationID, this.selectedTimeRange).subscribe((res) => {
      const labels: any[] = [];
      const airPressureData: any[] = [];
      const temperatureData: any[] = [];
      const rainfallRateData: any[] = [];
      const rainfallData: any[] = [];
      const windspeedData: any[] = [];
      const humidityData: any[] = [];

      res.forEach(data => {
        labels.push(new Date(data.timestamp).toLocaleString('en-US', { timeZone: 'America/Montevideo' }));
        airPressureData.push(data.bar_absolute);
        temperatureData.push(data.temp_out);
        rainfallRateData.push(data.rain_rate_hi_mm);
        rainfallData.push(data.rainfall_mm);
        windspeedData.push(data.wind_speed_hi);
        humidityData.push(data.hum_out);
      });

      this.lineChart.data.labels = labels;
      this.lineChart.data.datasets[0].data = airPressureData;
      this.lineChart.data.datasets[1].data = temperatureData;
      this.lineChart.data.datasets[2].data = humidityData;
      this.lineChart.data.datasets[3].data = rainfallRateData;
      this.lineChart.data.datasets[4].data = rainfallData;
      this.lineChart.data.datasets[5].data = windspeedData;

      this.lineChart.update();
      this.loading = false;
    });
  }

  onTimeRangeChange() {
    this.populateChart();
  }
  
}