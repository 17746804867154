<mat-sidenav-container class="sidebar-container" [hasBackdrop]="false">
    <mat-sidenav #sidenav="matSidenav" [opened]="(screenWidth > 640) ? 'true' : 'false'"
        [mode]="(screenWidth > 640) ? 'side' : 'over'"
        class="block fixed top-0 flex-row flex-nowrap shadow-xl bg-white flex flex-wrap items-center justify-between relative w-64 p-1">
        <div class="flex flex-col p-2 text-gray-600 cursor-default">
            <img src="../../../assets/logo.png" [routerLink]="['/']"
                class="w-2/3 place-self-center outline-none hover:cursor-pointer">
        </div>
        <hr class="mt-2 mb-1" />
        <div class="container">
            <div *ngIf="(superuser || admin || subadmin) && !featureFlags.isFeatureEnabled('showUruguay')" class="pt-1">
                <a [routerLink]="['/multimanagement']"
                    class="text-xs py-2 uppercase font-bold block rounded bg-gradient-to-b from-green-100 to-green-200 text-gray-500 hover:text-gray-700 flex items-center justify-center">
                    <i class="pr-4 fas fa-users text-sm"></i>
                    {{'SIDEBAR.MULTI' | translate }}
                </a>
                <div
                    class="min-w-full text-gray-500 text-xs uppercase font-bold block py-2 px-2 no-underline flex items-center justify-center">
                    {{'SIDEBAR.TITLE' | translate }}
                </div>
            </div>
            <div *ngIf="(superuser || admin || subadmin)">
                <div id="side_menu">
                    <div class="dropdown" #dropdownMenu>
                        <a class="btn pt-2 dropdown-toggle rounded bg-gradient-to-b from-green-100 to-green-200 text-gray-500 hover:text-gray-700 text-sm flex items-center justify-center"
                            id="btn_menu" role="button" data-toggle="dropdown" aria-expanded="true"
                            matTooltip="{{'SIDEBAR.TOOLTIP' | translate}}"
                            matTooltip="{{'SIDEBAR.TOOLTIP' | translate}}" matTooltipPosition="above"
                            (click)="toggleDropdown()">
                            <i class="pr-4 fa-solid fa-user text-xs"></i>
                            <span class="text-xs font-bold pr-2">
                                {{ defaultFarmer.split('|')[0] }}
                            </span>
                        </a>
                        <div class="dropdown-menu" [ngClass]="{ 'show': isDropdownOpen }">
                            <a class="dropdown-item" (click)="selectFarmer(farmer)" *ngFor="let farmer of farmers">
                                {{ farmer.split('|')[0] }}
                                <span [hidden]="true" class="text-xs">{{ farmer.split('|')[1] }}</span>
                            </a>
                        </div>
                    </div>
                </div>
                <hr class="mt-2">
            </div>
        </div>
        <ul class="flex-col min-w-full flex flex-col list-none px-2">
            <li class="items-center">
                <a [routerLink]="['/']"
                    class="text-xs uppercase py-3 font-bold block text-gray-700 hover:text-gray-500">
                    <i class="fas fa-home pr-2 text-sm text-gray-300"></i>
                    {{'SIDEBAR.HOME' | translate }}
                </a>
            </li>
            <li *ngIf="!featureFlags.isFeatureEnabled('showUruguay')" class="items-center">
                <a [routerLink]="['/management']"
                    class="text-xs uppercase py-3 font-bold block text-gray-700 hover:text-gray-500">
                    <i class="fas fa-tractor pr-2 text-sm text-gray-300"></i>
                    {{'SIDEBAR.MANAGEMENT' | translate }}
                </a>
            </li>
            <li class="items-center">
                <a [routerLink]="['/devices']"
                    class="text-xs uppercase py-3 font-bold block text-gray-700 hover:text-gray-500 cursor-pointer">
                    <i class="fa-solid fa-desktop pr-2 text-sm text-gray-300"></i>
                    {{'SIDEBAR.DEVICES' | translate }}
                </a>
            </li>
            <li class="items-center">
                <a [routerLink]="['/plots']"
                    class="text-xs uppercase py-3 font-bold block text-gray-700 hover:text-gray-500">
                    <i class="far fa-map pr-2 text-sm text-gray-300"></i>
                    {{'SIDEBAR.PLOTS' | translate }}
                </a>
            </li>
            <li class="items-center">
                <a [routerLink]="['/weather-stations']"
                    class="text-xs uppercase py-3 font-bold block text-gray-700 hover:text-gray-500 cursor-pointer">
                    <i class="fa-solid fa-cloud-sun pr-2 text-sm text-gray-300"></i>
                    {{'SIDEBAR.WEATHER' | translate }}
                </a>
            </li>
            <li *ngIf="!featureFlags.isFeatureEnabled('showUruguay')" class="items-center">
                <a [routerLink]="['/upload']"
                    class="text-xs uppercase py-3 font-bold block text-gray-700 hover:text-gray-500">
                    <i class="fas fa-upload pr-2 text-sm text-gray-300"></i>
                    {{'SIDEBAR.UPLOAD' | translate }}
                </a>
            </li>
            <li *ngIf="!featureFlags.isFeatureEnabled('showUruguay')" class="items-center">
                <a [routerLink]="['/history']"
                    class="text-xs uppercase py-3 font-bold block text-gray-700 hover:text-gray-500">
                    <i class="far fa-clipboard pr-2 text-sm text-gray-300"></i>
                    {{'SIDEBAR.HISTORICAL' | translate }}
                </a>
            </li>
            <li *ngIf="!featureFlags.isFeatureEnabled('showUruguay')" class="items-center">
                <a [routerLink]="['/carbon-footprint']"
                    class="text-xs uppercase py-3 font-bold block text-gray-700 hover:text-gray-500">
                    <i class="fas fa-shoe-prints pr-2 text-sm text-gray-300"></i>
                    {{'SIDEBAR.CARBON' | translate }}
                </a>
            </li>
            <li *ngIf="!featureFlags.isFeatureEnabled('showUruguay')" class="items-center">
                <a [routerLink]="['/storage']"
                    class="text-xs uppercase py-3 font-bold block text-gray-700 hover:text-gray-500">
                    <i class="fas fa-warehouse pr-2 text-sm text-gray-300"></i>
                    {{'SIDEBAR.STORAGE' | translate }}
                </a>
            </li>
            <li *ngIf="!featureFlags.isFeatureEnabled('showUruguay')" class="items-center">
                <a [routerLink]="['/reports']"
                    class="text-xs uppercase py-3 font-bold block text-gray-700 hover:text-gray-500">
                    <i class="fas fa-pen-to-square pr-2 text-sm text-gray-300"></i>
                    {{'SIDEBAR.REPORTS' | translate }}
                </a>
            </li>
            <li *ngIf="!featureFlags.isFeatureEnabled('showUruguay')" class="items-center">
                <a (click)="toggleRecommendations()"
                    class="text-xs uppercase pt-3 font-bold block text-gray-700 hover:text-gray-500 cursor-pointer">
                    <i class="fa-solid fa-thumbs-up pr-2 text-sm text-gray-300"></i>
                    {{'SIDEBAR.RECOMMEND' | translate }}
                </a>
            </li>
            <div *ngIf="isRecommendOpen" class="px-2">
                <li>
                    <a [routerLink]="['/recommendations/fertiliser']"
                        class="text-xs uppercase py-2 font-bold block text-gray-700 hover:text-gray-500 cursor-pointer">
                        <i class="fa-solid fa-sack-xmark pr-1 text-sm text-gray-300"></i>
                        {{'SIDEBAR.FERT' | translate }}
                    </a>
                </li>
                <li hidden>
                    <a class="text-xs uppercase py-2 font-bold block text-gray-700 hover:text-gray-500 cursor-pointer">
                        <i class="fa-solid fa-faucet-drip pr-1 text-sm text-gray-300"></i>
                        {{'SIDEBAR.IRRIGATE' | translate }}
                    </a>
                </li>
            </div>
        </ul>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>