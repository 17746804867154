import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-crop',
  templateUrl: './crop.component.html',
  styleUrls: ['./crop.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
})

export class CropComponent implements OnInit {

  id: any = '';
  plotCodes: any = [];
  dosagePerPlot: any = [];
  fertBatchNumbers: { batchNumber: string, rawProductId: string, name: string }[] = [];
  sprayingBatchNumbers: { batchNumber: string, rawProductId: string, name: string }[] = [];

  //fertilisation body
  fertilisationAgronomist: any = '';
  fertilisationBatchNumber: any = '';
  composition: any = '';
  commercialNames: any = [];
  fertilisationCommercialName: any = '';
  otherFertilisationCommercialName: any = '';
  fertilisationStartDate: any = '';
  fertilisationEndDate: any = '';
  fertilisationDosage: any = '';
  fertilisationDosageUnit: any = '';
  fertilisationDosageAppliedBy: any = '';
  fertilisationDosageAppliedBy100: any = '';
  fertilisationAmountUnit: any = '';
  fertilisationOperator: any = '';
  fertilisationPlotCode: any = '';
  applicationMethod: any = '';
  applicationMethods: any = [
    'Υδρολίπανση',
    'Λιπασματοοδιανομέας',
    'Χυδήν ανά δένδρο',
    'Χυδήν ανάμεσα από τις γραμμές',
    'Διαφυλλικά (Βυτίο 200lt)',
    'Διαφυλλικά (Βυτίο 300lt)',
    'Διαφυλλικά (Βυτίο 500lt)',
    'Διαφυλλικά (Βυτίο 1000lt)',
    'Διαφυλλικά (Βυτίο 1500lt)',
    'Διαφυλλικά (Τουρμπίνα 500lt)',
    'Διαφυλλικά (Τουρμπίνα 1000lt)',
    'Διαφυλλικά (Τουρμπίνα 1500lt',
    'Διαφυλλικά (Τουρμπίνα 2000lt)',
  ];
  totalN: any = '';
  totalP: any = '';
  totalK: any = '';
  productId: { [key: string]: string } = {};
  selectedProductId: string = '';

  //irrigation body
  irrigationDate: any = '';
  irrigationStartTime: any = '';
  irrigationEndTime: any = '';
  irrigationHours: any = '';
  irrDays: any = '';
  irrHours: any = '';
  irrMinutes: any = '';
  irrigationStartHours: any = '';
  irrigationStartMinutes: any = '';
  frequencyOptions: any = [
    'Καθημερινές (Δευ-Παρ)',
    'Καθημερινά',
    'ΕΒδομαδιαία',
    'Μηνιαία',
    'Ετησίως',
    'Προσαρμοσμένα'
  ];
  frequency: any = '';
  irrigationMethods: any = [
    'Επιφανειακή άρδευση (κατάκλιση)',
    'Άρδευση με καταιονισμό',
    'Στάγδην άρδευση'
  ];
  irrigationMethod: any = '';
  irrigationPlotCode: any = '';
  quantity: any = '';
  irrigationPlotsSum: any = 0;
  waterSourceQuantity: any = 0
  waterSource: any = ['Ατομική Γεώτρηση', 'Ομαδική Γεώτρηση', 'Δίκτυο', 'Πηγάδι'];
  sourceItem: any = ''

  //spraying body
  recommendedDosage: any = '';
  substance: any = ''
  sprayingAgronomist: any = '';
  solutionAmount: any = '';
  solutionUnit: any = '';
  solutionUnits: any = [
    'lt'
  ]
  sprayingBatchNumber: any = '';
  sprayingCommercialNames: any = []
  commercialName: any = '';
  otherCommercialName: any = '';
  cultivationStage: any = '';
  cultivationStages: any = [
    'Λήθαργος',
    'Έκπτυξη οφθαλμών',
    'Εμφάνιση πρώτων φύλλων',
    'Βλαστική ανάπτυξη',
    'Άνθηση',
    'Καρπόδεση',
    // 'Ανάπτυξη καρπού',
    // 'Ωρίμανση καρπού',
    'Συγκομιδή'
  ]
  sprayingStartDate: any = '';
  sprayingEndDate: any = '';
  dosage: any = '';
  dosageUnit: any = '';
  dosageUnitsAcres: any = [
    'τεμάχιο',
    'κιλά'
  ];
  dosageUnits: any = [
    'gr',
    'kg',
    'ml',
    'lt',
  ]
  sprayingHours: any = '';
  sprayingMinutes: any = '';
  implementationMethod: any = '';
  implementationMethods: any = [
    'Χειροκίνητοι ψεκαστήρες',
    'Ψεκαστήρας με δοχείο και κινητήρα βενζίνης',
    'Ψεκασμός από τρακτέρ',
    'Ψεκαστικά εδάφους',
    'Αυλοί & μπεκ ψεκασμού',
    // 'Σωλήνες και εξαρτήματα',
    // 'Ανάπτυξη καρπού',
    // 'Ωρίμανση καρπού',
    // 'Συγκομιδή',
    'Βυτίο 200lt',
    'Βυτίο 300lt',
    'Βυτίο 500lt',
    'Βυτίο 1000lt',
    'Βυτίο 1500lt',
    'Τουρμπίνα 500lt',
    'Τουρμπίνα 1000lt',
    'Τουρμπίνα 1500lt',
    'Τουρμπίνα 2000lt',
    'Ψεκαστήρα πλάτης',
    // 'Ενσωμάτωση'
  ];
  justification: any = '';
  sprayingOperator: any = '';
  phi: any = '';
  sprayingPlotCode: any = '';
  amountApplied: any = '';
  amountAppliedBy: any = '';
  amountUnit: any = '';
  amountUnits: any = [
    'lt'
  ];
  dosageAppliedBy: any = '';
  dosageBy: any = [
    '/ Στρέμμα',
    '/ Δέντρο',
    '/ 100 λίτρα'
  ];
  dosageBySpray: any = [
    '/ Στρέμμα',
    '/ Δέντρο',
  ];
  weatherCondition: any = '';
  weatherConditions: any = [
    'Ηλιοφάνεια',
    'Ήλιος με σύννεφα',
    'Συννεφιά',
    'Βροχή',
    'Χιόνι',
    'Χαλάζι'
  ]
  relatedActionFert: any = ['ΚΑΜΙΑ', '31.6-Ι', '31.6-ΙΑ', '31.6-ΙΒ']
  relatedActionCategoryFert: any = []
  relatedActionSpray: any = ['ΚΑΜΙΑ', '31.6-Β', '31.6-Γ', '31.6-Δ', '31.6-ΙΓ']
  sprayingProductId: { [key: string]: string } = {};

  //harvest body
  cleaning: any = '';
  client: any = '';
  harvestDate: any = '';
  lotNumber: any = '';
  harvestPlotCode: any = '';
  harvestSum: any = '';
  warnHarvest: boolean = false;
  harvestStartDate: any;
  harvestEndDate: any;
  harvestHours: any;
  harvestMinutes: any;

  //residue body
  residuePlotCode: any = '';
  residueAmount: any = '';
  residueDate: any = '';
  residueMethod: any = '';
  residueMethods: any = [
    'Συσσώρευση σε σωρούς ή λακκούβες',
    'Κομπόστ μη εξαναγκασμένου αερισμού',
    'Κομπόστ εξαναγκασμένου αερισμού',
    'Ενσωμάτωση στον αγρό ή εδαφοκάλυψη',
    'Καύση (υπό εξαίρεση)',
    'Απομάκρυνση από τον αγρό'
  ];
  residueMethodsEng: any = [
    'Removed; left untreated in heaps or pits',
    'Removed; non-forced-aeration compost',
    'Removed; forced-aeration compost',
    'Left on field; incorporated or mulch',
    'Burned',
    'Exported off farm'
  ];

  harvestId: any = '';
  residueId: any = '';
  harvests: any = [];
  public langStatus: any
  public residueChoose: any
  chooseTree: boolean = false;
  chooseAcre: boolean = false;
  chooseHundredSpray: boolean = false
  chooseTreeSpray: boolean = false;
  chooseAcreSpray: boolean = false;
  treeNumber: any = 0;
  acresNumber: any = 0;
  totalAmount: any;
  acresSpray: any = 0;
  treeSpray: any = 0;
  totalAmountSpray: any = 0;
  sprayLast: any;
  sprayName: any;
  sprayPhi: any;
  harvestPlot: any
  plotSize: any
  plotCode: any;
  wrongTime: boolean = false;
  documentNumber: any;
  residuesTreatments: any[] = []
  difPhi: any;
  wrongPhi: boolean = false;
  warnIrrigation: boolean = false;
  sumDateTime: any;
  sumDateTimeSpray: any;
  warnSpray: boolean = false;
  infoFertil: boolean = false;
  infoIrrigation: boolean = false;
  infoSpraying: boolean = false;
  infoHarvest: boolean = false;
  infoResidues: boolean = false;
  totalNSum: any;
  totalPSum: any;
  totalKSum: any;
  amountAppliedByHundred: any;
  cropInfo: any[] = [];
  arraySend: any[] = [];
  plotName: boolean = false;
  chooseHundred: boolean = false;

  allComplete: boolean = false;
  fertToggled: boolean = true;
  sprayToggled: boolean = true;
  selectedItems = new FormControl(<any>[]);
  plotChoose: any;
  array: any[] = []
  @ViewChild('select') select!: MatSelect;
  selectedOptions: any[] = [];
  childBoxValue: boolean = false;
  statusRound: boolean = false;
  sub100Value: boolean = false;
  chooseHundredAcres: boolean = false
  chooseHundredTree: boolean = false
  crop_id: any;
  relatedActionUnit: any
  relatedActionCategoryUnit: any
  relatedActionEmpty: boolean = false;
  relatedActionCategoryEmpty: boolean = false;
  relatedActionSprayUnit: any;
  relatedActionSprayEmpty: boolean = false;
  resID: any;
  itemToggled: boolean = false;
  itemAmount: any;
  methodEng: any

  constructor(
    private req: RequestService<any>,
    private _location: Location,
    public translate: TranslateService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.langStatus = this.translate.store.currentLang
    this.getId()
    if (this.langStatus === 'gr') {
      this.residueMethods = [
        'Συσσώρευση σε σωρούς ή λακκούβες',
        'Κομπόστ μη εξαναγκασμένου αερισμού',
        'Κομπόστ εξαναγκασμένου αερισμού',
        'Ενσωμάτωση στον αγρό ή εδαφοκάλυψη',
        'Καύση (υπό εξαίρεση)',
        'Απομάκρυνση από τον αγρό'
      ];
    } else if (this.langStatus === 'en') {
      this.residueMethods = [
        'Removed; left untreated in heaps or pits',
        'Removed; non-forced-aeration compost',
        'Removed; forced-aeration compost',
        'Left on field; incorporated or mulch',
        'Burned',
        'Exported off farm'
      ];
    }
    this.infoFertil = true;
    this.req.getRawProductsFertiliser().subscribe((data: any[]) => {
      this.commercialNames = data.map((item) => item.commercial_name);
      data.forEach((item) => {
        this.productId[item.commercial_name] = item._id;
      });
    });

    this.req.getRawProductsSpraying().subscribe((data: any[]) => {
      this.sprayingCommercialNames = data.map((item) => item.commercial_name);
      data.forEach((item) => {
        this.sprayingProductId[item.commercial_name] = item._id;
      });
    });
  }

  toggleCodeSelection(checked: boolean, code: any) {
    if (checked) {
      code[4].forEach((subtask: any) => {
        if (!this.selectedOptions.includes(subtask)) {
          this.selectedOptions.push(subtask);
        }
      });
    } else {
      this.selectedOptions = this.selectedOptions.filter(option => !code[4].includes(option));
    }
    this.updateAllComplete();
  }

  isCodeSelected(code: any): boolean {
    return code[4].every((subtask: any) => this.selectedOptions.includes(subtask));
  }

  toggleSelection(subtask: any, event: any) {
    const checked = event.target.checked;
    if (checked) {
      if (!this.selectedOptions.includes(subtask)) {
        this.selectedOptions.push(subtask);
      }
    } else {
      this.selectedOptions = this.selectedOptions.filter(option => option !== subtask);
    }
    this.updateAllComplete();
  }

  updateAllComplete() {
    this.allComplete = this.plotCodes.every((code: any) =>
      code[4].every((task: any) => this.selectedOptions.includes(task))
    );
  }

  setAll(checked: boolean) {
    if (checked) {
      this.plotCodes.forEach((code: any) => {
        code[4].forEach((subtask: any) => {
          if (!this.selectedOptions.includes(subtask)) {
            this.selectedOptions.push(subtask);
          }
        });
      });
    } else {
      this.plotCodes.forEach((code: any) => {
        code[4].forEach((subtask: any) => {
          this.selectedOptions = this.selectedOptions.filter(option => option !== subtask);
        });
      });
    }
    this.updateAllComplete();
  }

  onTabChangeManage(event: any) {
    if (event.index === 0) {
      this.infoFertil = true;
      this.infoIrrigation = false
      this.infoSpraying = false
      this.infoHarvest = false
      this.infoResidues = false
    } else if (event.index === 1) {
      this.infoFertil = false;
      this.infoIrrigation = true
      this.infoSpraying = false
      this.infoHarvest = false
      this.infoResidues = false
    } else if (event.index === 2) {
      this.infoFertil = false;
      this.infoIrrigation = false
      this.infoSpraying = true
      this.infoHarvest = false
      this.infoResidues = false
    } else if (event.index === 3) {
      this.infoFertil = false;
      this.infoIrrigation = false
      this.infoSpraying = false
      this.infoHarvest = true
      this.infoResidues = false
    } else if (event.index === 4) {
      this.infoFertil = false;
      this.infoIrrigation = false
      this.infoSpraying = false
      this.infoHarvest = false
      this.infoResidues = true
    }
  }

  async getId() {
    this.req.getSprayingProducts().subscribe((res) => {
      res.forEach(element => {
        this.sprayingBatchNumbers.push({ batchNumber: element.batch_number, rawProductId: element.raw_product_id, name: element.commercial_name });
      });
    })
    this.req.getFertiliserProducts().subscribe((res) => {
      res.forEach(element => {
        this.fertBatchNumbers.push({ batchNumber: element.batch_number, rawProductId: element.raw_product_id, name: element.commercial_name });
      });
    })
    this.req.getPlots().subscribe((res) => {
      res.forEach(element => {
        this.plotCodes.push([element.plot_code, element.size, element.crop_info[0].prod_tree_number,
        element.location, element.crop_info, element.crop_info.variety]);
      });
    })
  }

  getHarvests(subtask: any) {
    this.harvests = [];
    this.harvestId = '';
    if (!subtask) {
      return;
    }
    this.req.getPlotHarvest(subtask.crop_info_id).subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        this.harvests.push({ displayText: 'Plot ' + res[i].plot_code + ' - Date ' + res[i].datetime + ` (${res[i]._id.substring(0, 5)})`, harvestId: res[i]._id });
      }
    })
  }

  getTreatment(subtask: any) {
    this.crop_id = subtask.crop_info_id
    this.req.getPlotTreatment(this.crop_id).subscribe(res => {
      res.forEach((el: any) => {
        this.residuesTreatments.push(el)
      })
    })
  }

  getSpraying(subtask: any) {
    this.crop_id = subtask.crop_info_id
    this.plotSize = subtask.pef
    this.req.getPlotSpraying(this.crop_id).subscribe(res => {
      this.sprayLast = this.datePipe.transform(res[0].end_datetime, 'MM-dd-yyyy')
      this.sprayName = res[0].commercial_name
      this.sprayPhi = res[0].phi
    });
    this.difPhi = this.harvestDate - this.sprayLast
  }

  phiSpray(event: any) {
    const fullDate = this.datePipe.transform(this.harvestDate, 'MM-dd-yyyy')
    const harvestDateFormat: any = this.datePipe.transform(this.harvestDate, 'MM-dd-yyyy')?.split('-')[1]
    const sprayLastFormat: any = this.sprayLast.split('-')[1]
    const valuePhi = harvestDateFormat - sprayLastFormat
    const lastSprayDate = new Date(this.harvestDate)
    const harvestDate = new Date(this.sprayLast)
    const timeDiff = lastSprayDate.getTime() - harvestDate.getTime()
    const totalDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1
    if (totalDays >= this.sprayPhi) {
      this.warnHarvest = false
    } else {
      this.warnHarvest = true
    }
  }

  changeHarvest(event: any) {
    this.harvestSum = this.harvestPlot * this.plotSize
  }

  onChangeFert() {
    if (this.relatedActionUnit == 'ΚΑΜΙΑ') {
      this.relatedActionCategoryFert = ['ΚΑΜΙΑ']
    } else if (this.relatedActionUnit == '31.6-Ι') {
      this.relatedActionCategoryFert = ['Κατηγορία 1', 'Κατηγορία 2']
    } else if (this.relatedActionUnit == '31.6-ΙΑ') {
      this.relatedActionCategoryFert = ['Κατηγορία 1', 'Κατηγορία 2', 'Κατηγορία 3']
    } else if (this.relatedActionUnit == '31.6-ΙΒ') {
      this.relatedActionCategoryFert = ['Κατηγορία 1α', 'Κατηγορία 1β', 'Κατηγορία 2', 'Κατηγορία 3']
    }
  }

  changeFertilisation() {
    this.acresNumber = 0;
    this.treeNumber = 0;
    this.totalAmount = 0;
    if (this.selectedOptions.length > 0 && this.fertilisationDosageAppliedBy == ('/ Στρέμμα')) {
      this.chooseAcre = true;
      this.chooseTree = false;
      this.chooseHundred = false;
      this.sub100Value = false;
      this.selectedOptions.forEach((element: any) => {
        this.acresNumber += element.pef
      });
      const AmountFertil = this.acresNumber * this.fertilisationDosage
      this.totalAmount = AmountFertil.toFixed(2)
    } else if (this.selectedOptions.length > 0 && this.fertilisationDosageAppliedBy == ('/ Δέντρο')) {
      this.chooseAcre = false;
      this.chooseTree = true;
      this.chooseHundred = false;
      this.sub100Value = false;
      this.selectedOptions.forEach((element: any) => {
        this.treeNumber += element.prod_tree_number
      });
      const AmountFertil = this.treeNumber * this.fertilisationDosage
      this.totalAmount = AmountFertil.toFixed(2)
    }

    else if (this.selectedOptions.length > 0 && this.fertilisationDosageAppliedBy == ('/ 100 λίτρα')) {
      this.sub100Value = true
      if (this.fertilisationDosageAppliedBy100.includes('/ Στρέμμα')) {
        this.chooseHundredAcres = true
        this.chooseHundredTree = false
        this.chooseHundred = true;
        this.chooseTree = false;
        this.chooseAcre = false;
        this.selectedOptions.forEach((element: any) => {
          this.acresNumber += element.pef
        })
        this.totalAmount = (this.fertilisationDosage * this.solutionAmount / 100) * this.acresNumber
      } else if (this.fertilisationDosageAppliedBy100.includes('/ Δέντρο')) {
        this.chooseHundredTree = true
        this.chooseHundredAcres = false
        this.chooseHundred = true;
        this.chooseTree = false;
        this.chooseAcre = false;
        this.selectedOptions.forEach((element: any) => {
          this.treeNumber += element.prod_tree_number
        })
        const AmountFertil = (this.fertilisationDosage * this.solutionAmount / 100) * this.treeNumber
        this.totalAmount = AmountFertil.toFixed(2)
      }
    }
    this.calculateTotalNPK();
  }

  changeIrrigation() {
    this.irrigationPlotsSum = 0
    const endDateStart = new Date(this.irrigationEndTime).getTime()
    const startDateStart = new Date(this.irrigationStartTime).getTime()
    this.sumDateTime = endDateStart - startDateStart
    const totalSeconds = Math.floor(this.sumDateTime / 1000);
    const seconds = totalSeconds % 60
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor(totalSeconds / 3600);
    const days = Math.floor(hours / 24);
    const CalHours = minutes * 0.01667 + hours
    if (minutes < 0) {
      this.wrongTime = true
      this.irrMinutes = 0
    } else {
      this.wrongTime = false
      this.irrMinutes = minutes
    }
    if (hours < 0) {
      this.wrongTime = true
      this.irrHours = 0
    } else {
      this.wrongTime = false
      this.irrHours = hours
    }
    if (days < 0) {
      this.wrongTime = true
      this.irrDays = 0
    } else {
      this.wrongTime = false
      this.irrDays = days
    }
    if (this.irrHours >= 24) {
      this.warnIrrigation = true
    } else {
      this.warnIrrigation = false
    }
    this.selectedOptions.forEach((el: any) => {
      this.irrigationPlotsSum += el.pef
    });
    this.quantity = this.irrigationPlotsSum * this.waterSourceQuantity * CalHours
  }

  changeSpraying() {
    this.acresSpray = 0;
    this.totalAmountSpray = 0;
    this.sprayingHours = 0;
    this.sprayingMinutes = 0;
    const endDateStart = new Date(this.sprayingEndDate).getTime()
    const startDateStart = new Date(this.sprayingStartDate).getTime()
    this.sumDateTimeSpray = endDateStart - startDateStart
    this.sumDateTime = endDateStart - startDateStart
    const totalSeconds = Math.floor(this.sumDateTimeSpray / 1000);
    const seconds = totalSeconds % 60
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor(totalSeconds / 3600);
    const days = Math.floor(hours / 24);
    const CalHours = minutes * 0.01667 + hours
    if (hours < 0) {
      this.warnSpray = true
      this.sprayingHours = 0
    } else {
      this.warnSpray = false
      this.sprayingHours = hours
    }
    if (minutes < 0) {
      this.warnSpray = true
      this.sprayingMinutes = 0
    } else {
      this.warnSpray = false
      this.sprayingMinutes = minutes
    }
    if (days < 0) {
      this.warnSpray = true
    } else {
      this.warnSpray = false
    }
    if (this.selectedOptions.length > 0 && this.dosageAppliedBy == ('/ Στρέμμα')) {
      this.chooseAcreSpray = true
      this.chooseTreeSpray = false
      this.chooseHundredSpray = false
      this.selectedOptions.forEach((element: any) => {
        this.acresSpray += element.pef
      })
      this.totalAmountSpray = this.acresSpray * this.dosage
    } else if (this.selectedOptions.length > 0 && this.dosageAppliedBy == ('/ Δέντρο')) {
      this.chooseAcreSpray = false
      this.chooseTreeSpray = true
      this.chooseHundredSpray = false
      this.cropInfo.forEach((element: any) => {
        element.forEach((el: any) => {
          this.acresSpray += el.prod_tree_number
        })
      })
      this.totalAmountSpray = this.acresSpray * this.dosage
    } else if (this.selectedOptions.length > 0 && this.dosageAppliedBy.includes('100')) {
      this.chooseHundredSpray = true
      this.chooseAcreSpray = false
      this.chooseTreeSpray = false
      if (this.amountAppliedByHundred.includes('Στρέμμα')) {
        this.selectedOptions.forEach((element: any) => {
          this.acresSpray += element.pef
        })
        this.totalAmountSpray = (this.dosage * this.solutionAmount / 100) * this.acresSpray
      } else if (this.amountAppliedByHundred.includes('Δέντρο')) {
        this.selectedOptions.forEach((element: any) => {
          this.acresSpray += element.prod_tree_number
        })
        this.totalAmountSpray = (this.dosage * this.solutionAmount / 100) * this.acresSpray
      }
    }
  }

  changeResidues(event: any) {
    this.residueId = event.treatment + '-' + event.datetime
    this.resID = event._id
  }


  onFertBatchChange() {
    const selectedBatchNumber = this.fertilisationBatchNumber;
    if (selectedBatchNumber) {
      const selectedEntry = this.fertBatchNumbers.find((batch: any) => batch.batchNumber === selectedBatchNumber);
      if (selectedEntry) {
        const selectedRawProductId = selectedEntry.rawProductId;
        if (selectedRawProductId) {
          this.req.getRawProduct(selectedRawProductId).subscribe((res: any) => {
            this.fertilisationCommercialName = res.commercial_name;
            this.composition = res.substance;
            this.totalK = res.K;
            this.totalN = res.N;
            this.totalP = res.P;
            if (this.fertilisationDosageUnit === 'gr' || this.fertilisationDosageUnit === 'ml') {
              const totalAmount = this.totalAmount / 1000
              this.totalNSum = ((totalAmount * res.N) / 100).toFixed(7)
              this.totalPSum = ((totalAmount * res.P) / 100).toFixed(7)
              this.totalKSum = ((totalAmount * res.K) / 100).toFixed(7)
            } else if (this.fertilisationDosageUnit === 'kg' || this.fertilisationDosageUnit === 'lt') {
              this.totalNSum = ((this.totalAmount * res.N) / 100).toFixed(7)
              this.totalPSum = ((this.totalAmount * res.P) / 100).toFixed(7)
              this.totalKSum = ((this.totalAmount * res.K) / 100).toFixed(7)
            }
          });
        }
      }
    }
  }

  onFertCommercialNameChange() {
    const selectedCommercialName = this.fertilisationCommercialName;
    if (selectedCommercialName) {
      const exists = this.commercialNames.includes(selectedCommercialName);
      if (exists) {
        const selectedProductId = this.productId[selectedCommercialName];
        this.req.getRawProduct(selectedProductId).subscribe((res: any) => {
          this.totalN = res.N;
          this.totalP = res.P;
          this.totalK = res.K;
          this.composition = res.substance;

          this.calculateTotalNPK();
        });
      } else if (this.fertilisationCommercialName !== 'other') {
        this.totalN = '';
        this.totalP = '';
        this.totalK = '';
        this.composition = '';

        this.totalNSum = '';
        this.totalPSum = '';
        this.totalKSum = '';
      }
    }
  }

  onSprayingBatchChange() {
    this.commercialName = '';
    this.substance = '';
    this.justification = '';
    this.recommendedDosage = '';
    const selectedBatchNumber = this.sprayingBatchNumber;
    if (selectedBatchNumber) {
      const selectedEntry = this.sprayingBatchNumbers.find((batch: any) => batch.batchNumber === selectedBatchNumber);

      if (selectedEntry) {
        const selectedRawProductId = selectedEntry.rawProductId;

        if (selectedRawProductId) {
          this.req.getRawProduct(selectedRawProductId).subscribe((res: any) => {
            this.commercialName = res.commercial_name;
            this.substance = res.substance;
            this.justification = res.justification;
            this.recommendedDosage = res.dosage;
          })
        }
      }
    }
  }

  onSprayingCommercialNameChange() {
    const selectedCommercialName = this.commercialName;
    if (selectedCommercialName) {
      const exists = this.sprayingCommercialNames.includes(selectedCommercialName);
      if (exists) {
        const selectedProductId = this.sprayingProductId[selectedCommercialName];
        this.req.getRawProduct(selectedProductId).subscribe((res: any) => {
          this.substance = res.substance;
          this.justification = res.justification;
          this.recommendedDosage = res.dosage;
        })
      }
    }
  }

  calculateTotalNPK() {
    if (this.fertilisationDosageUnit === 'gr' || this.fertilisationDosageUnit === 'ml') {
      const totalAmount = parseFloat(this.totalAmount) / 1000;
      this.totalNSum = (totalAmount * parseFloat(this.totalN)) / 100;
      this.totalPSum = (totalAmount * parseFloat(this.totalP)) / 100;
      this.totalKSum = (totalAmount * parseFloat(this.totalK)) / 100;
    } else if (this.fertilisationDosageUnit === 'kg' || this.fertilisationDosageUnit === 'lt') {
      this.totalNSum = (parseFloat(this.totalAmount) * parseFloat(this.totalN)) / 100;
      this.totalPSum = (parseFloat(this.totalAmount) * parseFloat(this.totalP)) / 100;
      this.totalKSum = (parseFloat(this.totalAmount) * parseFloat(this.totalK)) / 100;
    }
  }

  onChangeHarvest() {
    const endDateStart = new Date(this.sprayingEndDate).getTime()
    const startDateStart = new Date(this.sprayingStartDate).getTime()
  }

  addFertilisation() {
    this.selectedOptions.forEach((item: any) => {
      this.arraySend.push(item.crop_info_id)
    });
    const related_action = 'CROP.RELATED_ACTION_CATEGORY_MSG'
    const related_action_category = 'CROP.RELATED_ACTION_CATEGORY_MSG'
    const actionAndCAtegory = 'CROP.RELATED_ACTION_AND_CATEGORY_MSG'
    const fertSubmit = 'CROP.SUCCES_FERT_SUBMIT'
    if (this.relatedActionUnit == undefined) {
      this.relatedActionEmpty = true
      this.relatedActionCategoryEmpty = false
      this.translate.get([related_action]).subscribe(trans => {
        Swal.fire(
          {
            icon: 'error',
            text: trans[related_action]
          }
        )
      })
    }
    if (this.relatedActionCategoryUnit == undefined) {
      this.relatedActionCategoryEmpty = true
      this.relatedActionEmpty = false
      this.translate.get([related_action_category]).subscribe(trans => {
        Swal.fire(
          {
            icon: 'error',
            text: trans[related_action_category]
          }
        )
      })
    }
    if (this.relatedActionUnit == undefined && this.relatedActionCategoryUnit == undefined) {
      this.relatedActionEmpty = true
      this.relatedActionCategoryEmpty = true
      this.translate.get([actionAndCAtegory]).subscribe(trans => {
        Swal.fire(
          {
            icon: 'error',
            text: trans[actionAndCAtegory]
          }
        )
      })
    }
    if (this.relatedActionUnit != undefined &&
      this.relatedActionCategoryUnit != undefined && this.relatedActionCategoryFert.length > 0) {
      this.relatedActionEmpty = false
      this.relatedActionCategoryEmpty = false
      const updatedData = {
        storage_usage: this.fertToggled,
        chemical_composition: this.composition ? this.composition : '',
        agronomist: this.fertilisationAgronomist ? this.fertilisationAgronomist : '',
        batch_number: this.fertilisationBatchNumber ? this.fertilisationBatchNumber : '',
        commercial_name: this.fertilisationCommercialName === 'other' ? this.otherFertilisationCommercialName : this.fertilisationCommercialName,
        cultivation_stage: this.cultivationStage,
        starting_date: this.fertilisationStartDate ? this.fertilisationStartDate : '',
        ending_date: this.fertilisationEndDate ? this.fertilisationEndDate : '',
        dosage: this.fertilisationDosage ? this.fertilisationDosage : 0,
        dosage_applied_by: this.fertilisationDosageAppliedBy ? this.fertilisationDosageAppliedBy : '',
        amount_of_solution: this.solutionAmount ? this.solutionAmount : 0,
        amount_of_solution_measurement_units: this.solutionUnit ? this.solutionUnit : '',
        amount_of_solution_applied_by: this.fertilisationDosageAppliedBy100 ? this.fertilisationDosageAppliedBy100 : '',
        dosage_measurement_units: this.fertilisationDosageUnit ? this.fertilisationDosageUnit : '',
        implementation_method: this.applicationMethod,
        operator: this.fertilisationOperator ? this.fertilisationOperator : '',
        crop_info_id: this.arraySend ? this.arraySend : '',
        weather_conditions: this.weatherCondition ? this.weatherCondition : '',
        related_action: this.relatedActionUnit ? this.relatedActionUnit : '',
        category_by_action: this.relatedActionCategoryUnit ? this.relatedActionCategoryUnit : '',
        n: this.totalN ? this.totalN : 0,
        p: this.totalP ? this.totalP : 0,
        k: this.totalK ? this.totalK : 0,
      };
      let dataObj = JSON.stringify(updatedData)
      this.req.postCropFertilisation(dataObj).subscribe(() => {
        this.translate.get([fertSubmit]).subscribe(trans => {
          Swal.fire({
            icon: 'success',
            text: trans[fertSubmit]
          })
        })
        setTimeout(() => {
          this._location.back();
        }, 1000);
      })
    }
  }

  addIrrigation() {
    this.selectedOptions.forEach((item: any) => {
      this.arraySend.push(item.crop_info_id)
    });
    const updatedData = {
      datetime: this.irrigationStartTime ? this.irrigationStartTime : '',
      end_datetime: this.irrigationEndTime ? this.irrigationEndTime : '',
      water_source: this.sourceItem ? this.sourceItem : '',
      hours: this.irrHours ? this.irrHours : '',
      irrigation_frequency: this.frequency ? this.frequency : '',
      irrigation_method: this.irrigationMethod ? this.irrigationMethod : '',
      water_m3_per_hour_per_acre: this.waterSourceQuantity ? this.waterSourceQuantity : '',
      crop_info_id: this.arraySend ? this.arraySend : '',
    };
    let dataObj = JSON.stringify(updatedData)
    this.req.postCropIrrigation(dataObj).subscribe(() => {
      Swal.fire(
        '',
        'Irrigation information updated successfully!',
        'success'
      )
      setTimeout(() => {
        this._location.back();
      }, 1000);
    })
  }

  addSpraying() {
    this.selectedOptions.forEach((item: any) => {
      this.arraySend.push(item.crop_info_id)
    });
    const related_action = 'CROP.RELATED_ACTION_MSG'
    const sprayingSubmit = 'CROP.SUCCES_SPRAYING_SUBMIT'
    if (this.relatedActionSprayUnit == undefined) {
      this.relatedActionSprayEmpty = true
      this.translate.get([related_action]).subscribe(trans => {
        Swal.fire({
          icon: 'error',
          text: trans[related_action]
        }
        )
      })
    } else {
      this.relatedActionSprayEmpty = false
      const updatedData = {
        storage_usage: this.sprayToggled,
        active_substance: this.substance ? this.substance : '',
        agronomist: this.sprayingAgronomist ? this.sprayingAgronomist : '',
        amount_of_solution: this.solutionAmount ? this.solutionAmount : 0,
        amount_of_solution_measurement_units: this.solutionUnit ? this.solutionUnit : '',
        amount_of_solution_applied_by: this.amountAppliedByHundred ? this.amountAppliedByHundred : '',
        batch_number: this.sprayingBatchNumber ? this.sprayingBatchNumber : '',
        commercial_name: this.commercialName === 'other' ? this.otherCommercialName : this.commercialName,
        cultivation_stage: this.cultivationStage ? this.cultivationStage : '',
        datetime: this.sprayingStartDate ? this.sprayingStartDate : '',
        end_datetime: this.sprayingEndDate ? this.sprayingEndDate : '',
        dosage: this.dosage ? this.dosage : 0,
        dosage_applied_by: this.dosageAppliedBy ? this.dosageAppliedBy : '',
        dosage_measurement_units: this.dosageUnit ? this.dosageUnit : '',
        hours: this.sprayingHours ? this.sprayingHours : '',
        implementation_method: this.implementationMethod ? this.implementationMethod : '',
        justification: this.justification ? this.justification : '',
        operator: this.sprayingOperator ? this.sprayingOperator : '',
        phi: this.phi ? this.phi : 0,
        crop_info_id: this.arraySend ? this.arraySend : '',
        weather_conditions: this.weatherCondition ? this.weatherCondition : '',
        related_action: this.relatedActionSprayUnit ? this.relatedActionSprayUnit : '',
        amount_of_item: this.itemAmount ? this.itemAmount : 0,
      };
      let dataObj = JSON.stringify(updatedData)
      this.req.postCropSpraying(dataObj).subscribe(() => {
        this.translate.get([sprayingSubmit]).subscribe(trans => {
          Swal.fire({
            icon: 'success',
            text: trans[sprayingSubmit]
          })
        })
        setTimeout(() => {
          this._location.back();
        }, 1000);
      })
    }
  }

  addHarvest() {
    const updatedData = {
      cleaning_of_harvesting_equipment: this.cleaning ? this.cleaning : '',
      client: this.client ? this.client : '',
      datetime: this.harvestDate ? this.harvestDate : '',
      lot_number: this.lotNumber ? this.lotNumber : '',
      crop_info_id: this.crop_id ? this.crop_id : '',
      document_number: this.documentNumber ? this.documentNumber : '',
      summ_unit_measurement: this.fertilisationDosageUnit ? this.fertilisationDosageUnit : '',
      harvest_per_acre: this.harvestPlot ? this.harvestPlot : ''
    };
    let dataObj = JSON.stringify(updatedData)
    if (this.warnHarvest === true) {
      Swal.fire(
        '',
        'Wrong Phi!',
        'error'
      )
    } else if (this.warnHarvest === false) {
      this.req.postCropHarvest(dataObj).subscribe(() => {
        Swal.fire(
          '',
          'Harvest information updated successfully!',
          'success'
        )
        setTimeout(() => {
          this._location.back();
        }, 1000);
      })
    }
  }

  onResidues(selectedMethod: any) {
    this.residueChoose = this.residueMethods.indexOf(selectedMethod);
  }

  addResidues() {
    this.residueMethodsEng.forEach((el: any, index: any) => {
      if (index == this.residueChoose) {
        this.methodEng = el
      }
    });
    const updatedData = {
      datetime: this.residueDate ? this.residueDate : '',
      management_method: this.methodEng ? this.methodEng : '',
      crop_info_id: this.crop_id,
      total_amount: this.residueAmount ? this.residueAmount : '',
      harvest_id: this.harvestId ? this.harvestId : '',
      related_treatment: this.resID ? this.resID : ''
    };
    let dataObj = JSON.stringify(updatedData)
    this.req.postCropResidues(dataObj).subscribe(() => {
      Swal.fire(
        '',
        'Residues information updated successfully!',
        'success'
      )
      setTimeout(() => {
        this._location.back();
      }, 1000);
    })
  }

  onItemToggleChange() {
    if (this.itemToggled) {
      this.sprayToggled = false;
    }
  }
}