import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { RequestService } from 'src/app/services/request.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { FeatureFlagsService } from 'src/app/services/feature-flags.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  screenWidth: number;
  admin: boolean = false;
  subadmin: boolean = false;
  superuser: boolean = false;
  relatedFarmers: any = [];
  farmers: any[] = [];
  defaultFarmer: string = '';
  isDropdownOpen: boolean = false;
  isRecommendOpen: boolean = false;
  userFarmer: boolean = false

  @ViewChild('sidenav') public sidebar!: MatSidenav;
  @ViewChild('dropdownMenu') public dropdownMenu!: ElementRef;

  constructor(private sidebarService: SidebarService, private authorizationService: AuthorizationService, private req: RequestService<any>, public featureFlags: FeatureFlagsService) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(event.target)) {
      // If the clicked element is not inside the dropdown, close it
      this.isDropdownOpen = false;
    }
  }

  ngOnInit() {
    this.req.getUserRelationship().subscribe(res => {
      if (Array.isArray(res)) {
        if (res.includes('rights')) {
          this.userFarmer = true
        } else {
          this.authorizationService.superuser$.subscribe(superuser => {
            this.superuser = superuser;

            if (this.superuser === true) {
              this.initializeFarmer();
            }
          });

          this.authorizationService.admin$.subscribe(admin => {
            this.admin = admin;

            if (this.admin === true) {
              this.initializeFarmer();
            }
          });

          this.authorizationService.subadmin$.subscribe(subadmin => {
            this.subadmin = subadmin;

            if (this.subadmin === true) {
              this.initializeFarmer();
            }
          });

          if (this.superuser === true) {
            this.initializeFarmer();

          }
        }
      }
    })


    this.authorizationService.admin$.subscribe(admin => {
      this.admin = admin;

      if (this.admin === true) {
        this.initializeFarmer();
      }
    });

    this.authorizationService.subadmin$.subscribe(subadmin => {
      this.subadmin = subadmin;

      if (this.subadmin === true) {
        this.initializeFarmer();
      }
    });

  }

  ngAfterViewInit() {
    this.sidebarService.setSidenav(this.sidebar);
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  initializeFarmer() {
    this.req.getUserRelationship().subscribe(res => {
      this.relatedFarmers = [];
      this.farmers = [];
      this.relatedFarmers.push(res[0].related_farmers)
      this.relatedFarmers[0].forEach((element: { first_name: any; last_name: any; keycloak_id: any; }) => {
        this.farmers.push(element.last_name + ' ' + element.first_name + '|' + element.keycloak_id);
        if (element.keycloak_id === res[0].selected_keycloak_id) {
          this.defaultFarmer = element.last_name + ' ' + element.first_name + '|' + element.keycloak_id;
        }
      });
    })
  }

  selectFarmer(farmer: string) {
    const [name, id] = farmer.split('|');
    this.defaultFarmer = farmer;
    this.isDropdownOpen = false;

    let updatedData = {
      selected_keycloak_id: id,
    }

    this.req.updateSelectedFarmer(updatedData).subscribe({
      next: () => {
        location.reload();
      }
    })
  }

  toggleRecommendations() {
    this.isRecommendOpen = !this.isRecommendOpen;
  }

}