<h1 class="text-lg font-semibold m-6 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
  <div *ngIf="translate.currentLang === 'en'">
    {{name}} {{ 'METEO.STATION'| translate }}
  </div>
  <div *ngIf="translate.currentLang === 'gr'">
    {{ 'METEO.STATION'| translate }} {{name}}
  </div>
</h1>
<div class="container p-1" style="margin-top: -1%;">
  <div class="row">
    <div class="col-6">
      <mat-card class="w-full h-full flex flex-col">
        <mat-card-header class="w-full" id="mat-card-header">
          <mat-card-subtitle>
            {{'METEO.LAST' | translate}}: {{latest}}
          </mat-card-subtitle>
        </mat-card-header>
        <div class="w-full">
          <div class="grid grid-cols-3 gap-2 m-3 mt-2">
            <div *ngFor="let item of items"
              class="item cursor-pointer rounded flex flex-col justify-center items-center py-12 bg-gradient-to-b from-green-100 to-green-200 relative">
              <img src="../../../../assets/weather.png"
                class="absolute w-12 img-fluid rounded-rectangle img-thumbnail left-0 m-2">
              <div class="text-gray-600 text-xs font-semibold"
                style="position: absolute; top: 0.5rem; right:0.5rem; margin-left: auto; margin-top: auto;">
                {{ item | translate }}
              </div>
              <div class="text-gray-600 text-sm font-semibold"
                style="position: absolute; bottom: 0.5rem; right:0.5rem; margin-left: auto; margin-top: auto;">
                {{ itemValues[item] }}
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-6">
      <div id="map"></div>
    </div>
  </div>
  <div class="flex flex-col justify-center items-center">
    <div class="text-center d-flex align-items-center justify-content-center">
      <mat-spinner *ngIf="loading" diameter="40" color="accent"></mat-spinner>
    </div>
  </div>
  <mat-card id="cardsBottom" class="mt-2">
    <mat-card-header>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedTimeRange" (change)="onTimeRangeChange()">
        <mat-radio-button matTooltip='{{ "METEO.TOOLTIP_DAY" | translate }}' value="day">{{ "METEO.DAY" | translate
          }}</mat-radio-button>
        <mat-radio-button matTooltip='{{ "METEO.TOOLTIP_WEEK" | translate }}' value="week">{{ "METEO.WEEK" | translate
          }}</mat-radio-button>
      </mat-radio-group>
    </mat-card-header>
    <mat-card-content>
      <canvas #lineCanvas width="400" height="320"></canvas>
    </mat-card-content>
  </mat-card>
</div>