import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RequestService } from 'src/app/services/request.service';
import { GridOptions } from 'ag-grid-community';
import { formatDate } from '@angular/common';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-multimanagement',
  templateUrl: './multimanagement.component.html',
  styleUrls: ['./multimanagement.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class MultimanagementComponent implements OnInit {

  gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    columnDefs: [
      {
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 10,
        suppressSizeToFit: true
      },
      { headerName: 'Καλλιέργεια', field: 'crop_type', resizable: true },
      { headerName: 'Όνομα', field: 'first_name', resizable: true },
      { headerName: 'Επώνυμο', field: 'last_name', resizable: true },
      { headerName: 'Τοποθεσία', field: 'location', resizable: true },
      { headerName: 'Περιφερειακή Ενότητα', field: 'municipal_community', resizable: true },
      { headerName: 'Έκταση', field: 'size', resizable: true },
      { headerName: 'Ποικιλία', field: 'variety', resizable: true },
      { headerName: 'ΑΦΜ', field: 'vat_number', resizable: true }
    ],
    rowData: [],
    rowSelection: 'multiple',
    animateRows: true,
    pagination: true,
    paginationPageSize: 15,
    suppressRowClickSelection: true
  };

  defaultColDef: any = {
    sortable: true,
    filter: true,
    resizable: true,
    filterParams: {
      buttons: ['reset', 'apply'],
      filterOptions: ['contains', 'notContains', 'equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'],
    }
  }

  rowData: any[] = [];
  currentStep: number = 1;
  cropIds: any = [];
  arraySend: any[] = [];
  plotName: boolean = false;
  fertilisationAgronomist: any = '';
  fertilisationBatchNumber: any = '';
  composition: any = '';
  commercialNames: any = [];
  fertilisationCommercialName: any = '';
  otherFertilisationCommercialName: any = '';
  fertilisationStartDate: any = '';
  fertilisationEndDate: any = '';
  fertilisationDosage: any = '';
  fertilisationDosageUnit: any = '';
  fertilisationDosageAppliedBy: any = '';
  fertilisationDosageAppliedBy100: any = '';
  fertilisationAmountUnit: any = '';
  fertilisationOperator: any = '';
  fertilisationPlotCode: any = '';
  applicationMethod: any = '';
  applicationMethods: any = [
    'Υδρολίπανση',
    'Λιπασματοοδιανομέας',
    'Χυδήν ανά δένδρο',
    'Χυδήν ανάμεσα από τις γραμμές',
    'Διαφυλλικά (Βυτίο 200lt)',
    'Διαφυλλικά (Βυτίο 300lt)',
    'Διαφυλλικά (Βυτίο 500lt)',
    'Διαφυλλικά (Βυτίο 1000lt)',
    'Διαφυλλικά (Βυτίο 1500lt)',
    'Διαφυλλικά (Τουρμπίνα 500lt)',
    'Διαφυλλικά (Τουρμπίνα 1000lt)',
    'Διαφυλλικά (Τουρμπίνα 1500lt',
    'Διαφυλλικά (Τουρμπίνα 2000lt)',
  ];

  cultivationStage: any = '';
  cultivationStages: any = [
    'Λήθαργος',
    'Έκπτυξη οφθαλμών',
    'Εμφάνιση πρώτων φύλλων',
    'Βλαστική ανάπτυξη',
    'Άνθηση',
    'Καρπόδεση',
    // 'Ανάπτυξη καρπού',
    // 'Ωρίμανση καρπού',
    'Συγκομιδή'
  ]
  weatherCondition: any = '';
  weatherConditions: any = [
    'Ηλιοφάνεια',
    'Ήλιος με σύννεφα',
    'Συννεφιά',
    'Βροχή',
    'Χιόνι',
    'Χαλάζι'
  ]
  productId: { [key: string]: string } = {};
  selectedProductId: string = '';
  totalN: any = '';
  totalP: any = '';
  totalK: any = '';
  solutionAmount: any = '';
  solutionUnit: any = '';
  solutionUnits: any = [
    'lt'
  ]
  selectedOptions: any[] = [];
  dosage: any = '';
  dosageUnit: any = '';
  dosageUnitsAcres: any = [
    'τεμάχιο',
    'κιλά'
  ];
  dosageUnits: any = [
    'gr',
    'kg',
    'ml',
    'lt',
  ]
  dosageBy: any = [
    '/ Στρέμμα',
    '/ Δέντρο',
    '/ 100 λίτρα'
  ];
  dosageBySpray: any = [
    '/ Στρέμμα',
    '/ Δέντρο',
  ];
  relatedActionUnit: any;
  relatedActionSprayUnit: any;
  relatedActionFert: any = ['ΚΑΜΙΑ', '31.6-Ι', '31.6-ΙΑ', '31.6-ΙΒ']
  relatedActionSpray: any = ['ΚΑΜΙΑ', '31.6-Β', '31.6-Γ', '31.6-Δ', '31.6-ΙΓ']
  relatedActionCategoryFert: any = []
  relatedActionCategoryEmpty: boolean = false;
  relatedActionSprayEmpty: boolean = false;
  relatedActionEmpty: boolean = false;
  relatedActionCategoryUnit: any;
  implementationMethod: any = '';
  implementationMethods: any = [
    'Χειροκίνητοι ψεκαστήρες',
    'Ψεκαστήρας με δοχείο και κινητήρα βενζίνης',
    'Ψεκασμός από τρακτέρ',
    'Ψεκαστικά εδάφους',
    'Αυλοί & μπεκ ψεκασμού',
    // 'Σωλήνες και εξαρτήματα',
    // 'Ανάπτυξη καρπού',
    // 'Ωρίμανση καρπού',
    // 'Συγκομιδή',
    'Βυτίο 200lt',
    'Βυτίο 300lt',
    'Βυτίο 500lt',
    'Βυτίο 1000lt',
    'Βυτίο 1500lt',
    'Τουρμπίνα 500lt',
    'Τουρμπίνα 1000lt',
    'Τουρμπίνα 1500lt',
    'Τουρμπίνα 2000lt',
    'Ψεκαστήρα πλάτης',
    // 'Ενσωμάτωση'
  ];
  justification: any = '';
  substance: any = '';
  recommendedDosage: any = '';
  commercialName: any = '';
  otherCommercialName: any = '';
  sprayingProductId: { [key: string]: string } = {};
  sprayingCommercialNames: any = []
  itemAmount: any;
  sprayingStartDate: any = '';
  sprayingEndDate: any = '';
  sprayingHours: any = '';
  sprayingMinutes: any = '';
  sprayingOperator: any = '';
  phi: any = '';
  dosageAppliedBy: any = '';
  acresSpray: any = 0;
  totalAmountSpray: any = 0;
  sumDateTime: any;
  sumDateTimeSpray: any;
  cropInfo: any[] = [];
  amountAppliedByHundred: any;
  sprayingAgronomist: any = '';
  sprayingBatchNumber: any = '';
  treatment: any = '';
  treatments: any = [
    'TREATMENT.SEEDBED',
    'TREATMENT.1ST',
    'TREATMENT.2ND',
    'TREATMENT.WEED',
    'TREATMENT.IMPROVEMENT',
    'TREATMENT.FERT',
    'TREATMENT.RESIDUES',
    'TREATMENT.ENTOMOLOGICAL',
    'TREATMENT.FLAT',
    'TREATMENT.PRUNING',
    'TREATMENT.CLEAN',
    'TREATMENT.PLOWING',
    'TREATMENT.MILLING',
    'TREATMENT.MOWING',
  ];
  treatmentStartDate: any = '';
  treatmentEndDate: any = '';
  startDateTime: any = '';
  endDateTime: any = '';
  warnSpray: boolean = false;
  infoFertil: boolean = false;
  infoSpraying: boolean = false;
  infoTreatment: boolean = true;
  itemToggled: boolean = false;
  arraySendLength: any;
  dataObj: any;
  summerTreatment: boolean = false;
  validStartDate: Date = new Date('2024-06-01T00:00:00');
  validEndDate: Date = new Date('2024-08-31T23:59:59');

  constructor(private req: RequestService<any>, public translate: TranslateService, public _location: Location) { }

  ngOnInit() {
    this.req.getMultimanagementData().subscribe((res: any[]) => {
      this.rowData = res
      this.rowData = res.map(element => {
        const sizeAsNumber = parseFloat(element.size);
        if (!isNaN(sizeAsNumber)) {
          return {
            ...element,
            size: sizeAsNumber.toFixed(2)
          };
        } else {
          return element;
        }
      });
    });

    this.req.getRawProductsFertiliser().subscribe((data: any[]) => {
      this.commercialNames = data.map((item) => item.commercial_name);
      data.forEach((item) => {
        this.productId[item.commercial_name] = item._id;
      });
    });

    this.req.getRawProductsSpraying().subscribe((data: any[]) => {
      this.sprayingCommercialNames = data.map((item) => item.commercial_name);
      data.forEach((item) => {
        this.sprayingProductId[item.commercial_name] = item._id;
      });
    });
    this.summerTreatment = false;
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  onRowClicked(event: any) {
    if (event.node.isSelected()) {
      event.node.setSelected(false);
    } else {
      event.node.setSelected(true);
    }
  }

  onTabChangeManage(event: any) {
    if (event.index === 0) {
      this.infoFertil = false;
      this.infoSpraying = false
      this.infoTreatment = true
    } else if (event.index === 1) {
      this.infoFertil = true;
      this.infoSpraying = false;
      this.infoTreatment = false;
    } else if (event.index === 2) {
      this.infoFertil = false;
      this.infoSpraying = true;
      this.infoTreatment = false;
    }
  }

  nextStep() {
    if (this.currentStep < 3) {
      if (this.gridOptions.api) {
        const selectedRows = this.gridOptions.api.getSelectedRows();

        this.cropIds.push(selectedRows);
      }
      this.currentStep++;
    }
    if (this.currentStep === 3) {

      this.cropIds[0].forEach((item: any) => {
        this.arraySend.push(item.crop_info_id)
      });

      this.arraySendLength = this.arraySend.length
    }
  }

  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  onChangeFert() {
    if (this.relatedActionUnit == 'ΚΑΜΙΑ') {
      this.relatedActionCategoryFert = ['ΚΑΜΙΑ']
    } else if (this.relatedActionUnit == '31.6-Ι') {
      this.relatedActionCategoryFert = ['Κατηγορία 1', 'Κατηγορία 2']
    } else if (this.relatedActionUnit == '31.6-ΙΑ') {
      this.relatedActionCategoryFert = ['Κατηγορία 1', 'Κατηγορία 2', 'Κατηγορία 3']
    } else if (this.relatedActionUnit == '31.6-ΙΒ') {
      this.relatedActionCategoryFert = ['Κατηγορία 1α', 'Κατηγορία 1β', 'Κατηγορία 2', 'Κατηγορία 3']
    }
  }

  onFertCommercialNameChange() {
    const selectedCommercialName = this.fertilisationCommercialName;
    if (selectedCommercialName) {
      const exists = this.commercialNames.includes(selectedCommercialName);
      if (exists) {
        const selectedProductId = this.productId[selectedCommercialName];
        this.req.getRawProduct(selectedProductId).subscribe((res: any) => {
          this.totalN = res.N;
          this.totalP = res.P;
          this.totalK = res.K;
          this.composition = res.substance;

        });
      } else if (this.fertilisationCommercialName !== 'other') {
        this.totalN = '';
        this.totalP = '';
        this.totalK = '';
        this.composition = '';
      }
    }
  }

  onSprayingCommercialNameChange() {
    const selectedCommercialName = this.commercialName;
    if (selectedCommercialName) {
      const exists = this.sprayingCommercialNames.includes(selectedCommercialName);
      if (exists) {
        const selectedProductId = this.sprayingProductId[selectedCommercialName];
        this.req.getRawProduct(selectedProductId).subscribe((res: any) => {
          this.substance = res.substance;
          this.justification = res.justification;
          this.recommendedDosage = res.dosage;
        })
      }
    }
  }

  changeSpraying() {
    this.sprayingHours = 0;
    this.sprayingMinutes = 0;
    const endDateStart = new Date(this.sprayingEndDate).getTime()
    const startDateStart = new Date(this.sprayingStartDate).getTime()
    this.sumDateTimeSpray = endDateStart - startDateStart
    this.sumDateTime = endDateStart - startDateStart
    const totalSeconds = Math.floor(this.sumDateTimeSpray / 1000);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const hours = Math.floor(totalSeconds / 3600);
    const days = Math.floor(hours / 24);
    if (hours < 0) {
      this.warnSpray = true
      this.sprayingHours = 0
    } else {
      this.warnSpray = false
      this.sprayingHours = hours
    }
    if (minutes < 0) {
      this.warnSpray = true
      this.sprayingMinutes = 0
    } else {
      this.warnSpray = false
      this.sprayingMinutes = minutes
    }
    if (days < 0) {
      this.warnSpray = true
    } else {
      this.warnSpray = false
    }
  }

  setFixedTime() {
    const fixedTime = 'T09:00';

    if (this.treatmentStartDate) {
      this.startDateTime = formatDate(this.treatmentStartDate, 'yyyy-MM-dd', 'en') + fixedTime;
    }

    if (this.treatmentEndDate) {
      this.endDateTime = formatDate(this.treatmentEndDate, 'yyyy-MM-dd', 'en') + fixedTime;
    }
  }

  pruningCalculate(event: any) {
    const startDate = new Date(this.treatmentStartDate);
    const endDate = new Date(this.treatmentEndDate);
    if ((this.outOfRangeDate(startDate) || this.outOfRangeDate(endDate)) && (this.treatment == 'TREATMENT.2ND' || this.treatment == 'TREATMENT.1ST')) {
      this.summerTreatment = true
    } else {
      this.summerTreatment = false
    }
  }

  outOfRangeDate(date: Date): boolean {
    return date < this.validStartDate || date > this.validEndDate
  }

  addData() {
    const currentLang = this.translate.currentLang;

    this.setFixedTime();
    const title = 'MULTI.ADD_DATA_TITLE';
    const yesDelete = 'MULTI.YES_SAVE';
    const deletedText = 'MULTI.POST_TEXT';
    const cancel = 'PRODUCT.CANCEL';
    this.translate.use('gr').subscribe(() => {
      this.translate.get(this.treatment).subscribe((translatedTreatment: string) => {
        if (this.infoTreatment) {

          const updatedData = {
            start_date: this.startDateTime ? this.startDateTime : '',
            end_date: this.endDateTime ? this.endDateTime : '',
            crop_info_id: this.arraySend ? this.arraySend : '',
            treatment: translatedTreatment ? translatedTreatment : '',
          };
          this.dataObj = JSON.stringify(updatedData)
        }

        if (this.infoFertil) {
          const updatedData = {
            storage_usage: false,
            cultivation_stage: this.cultivationStage,
            implementation_method: this.applicationMethod,
            weather_conditions: this.weatherCondition,
            starting_date: this.fertilisationStartDate ? this.fertilisationStartDate : '',
            ending_date: this.fertilisationEndDate ? this.fertilisationEndDate : '',
            crop_info_id: this.arraySend ? this.arraySend : '',
            agronomist: this.fertilisationAgronomist ? this.fertilisationAgronomist : '',
            operator: this.fertilisationOperator ? this.fertilisationOperator : '',
            batch_number: this.fertilisationBatchNumber ? this.fertilisationBatchNumber : '',
            commercial_name: this.fertilisationCommercialName === 'other' ? this.otherFertilisationCommercialName : this.fertilisationCommercialName,
            chemical_composition: this.composition ? this.composition : '',
            related_action: this.relatedActionUnit ? this.relatedActionUnit : '',
            category_by_action: this.relatedActionCategoryUnit ? this.relatedActionCategoryUnit : '',
            dosage: this.fertilisationDosage ? this.fertilisationDosage : 0,
            dosage_measurement_units: this.fertilisationDosageUnit ? this.fertilisationDosageUnit : '',
            dosage_applied_by: this.fertilisationDosageAppliedBy ? this.fertilisationDosageAppliedBy : '',
            amount_of_solution: this.solutionAmount ? this.solutionAmount : 0,
            amount_of_solution_measurement_units: this.solutionUnit ? this.solutionUnit : '',
            amount_of_solution_applied_by: this.fertilisationDosageAppliedBy100 ? this.fertilisationDosageAppliedBy100 : '',
            n: this.totalN ? this.totalN : 0,
            p: this.totalP ? this.totalP : 0,
            k: this.totalK ? this.totalK : 0,
          };
          this.dataObj = JSON.stringify(updatedData)
        }

        if (this.infoSpraying) {
          const updatedData = {
            storage_usage: false,
            active_substance: this.substance ? this.substance : '',
            agronomist: this.sprayingAgronomist ? this.sprayingAgronomist : '',
            amount_of_solution: this.solutionAmount ? this.solutionAmount : 0,
            amount_of_solution_measurement_units: this.solutionUnit ? this.solutionUnit : '',
            amount_of_solution_applied_by: this.amountAppliedByHundred ? this.amountAppliedByHundred : '',
            batch_number: this.sprayingBatchNumber ? this.sprayingBatchNumber : '',
            commercial_name: this.commercialName === 'other' ? this.otherCommercialName : this.commercialName,
            cultivation_stage: this.cultivationStage ? this.cultivationStage : '',
            datetime: this.sprayingStartDate ? this.sprayingStartDate : '',
            end_datetime: this.sprayingEndDate ? this.sprayingEndDate : '',
            dosage: this.dosage ? this.dosage : 0,
            dosage_applied_by: this.dosageAppliedBy ? this.dosageAppliedBy : '',
            dosage_measurement_units: this.dosageUnit ? this.dosageUnit : '',
            hours: this.sprayingHours ? this.sprayingHours : '',
            implementation_method: this.implementationMethod ? this.implementationMethod : '',
            justification: this.justification ? this.justification : '',
            operator: this.sprayingOperator ? this.sprayingOperator : '',
            phi: this.phi ? this.phi : 0,
            crop_info_id: this.arraySend ? this.arraySend : '',
            weather_conditions: this.weatherCondition ? this.weatherCondition : '',
            related_action: this.relatedActionSprayUnit ? this.relatedActionSprayUnit : '',
            amount_of_item: this.itemAmount ? this.itemAmount : 0,
          };
          this.dataObj = JSON.stringify(updatedData)

        }

        this.translate.get([title, yesDelete, yesDelete, cancel, deletedText])
          .subscribe(translations => {
            Swal.fire({
              title: translations[title],
              icon: 'warning',
              showCancelButton: true,
              cancelButtonColor: 'rgb(147, 163, 160)',
              confirmButtonColor: 'rgb(34, 139, 34)',
              confirmButtonText: translations[yesDelete],
              cancelButtonText: translations[cancel],
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire({
                  title: '',
                  text: translations[deletedText],
                  icon: 'success',
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                  timer: 3000
                });
                if (this.infoTreatment) {
                  this.translate.use(currentLang).subscribe(() => {
                    this.req.postTreatmentMultiData(this.dataObj).subscribe(() => {
                      setTimeout(() => {
                        this._location.back();
                      })
                    })
                  })
                }
                if (this.infoFertil) {
                  this.req.postFertiliserMultiData(this.dataObj).subscribe(() => {
                    setTimeout(() => {
                      this._location.back();
                    })
                  })
                }
                if (this.infoSpraying) {
                  this.req.postSprayingMultiData(this.dataObj).subscribe(() => {
                    setTimeout(() => {
                      this._location.back();
                    })
                  })
                }
              }
            });
          });
      })
    })
  }
}