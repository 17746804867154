<h1 class="text-lg font-semibold p-1 text-uppercase text-gray-600" style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);">
  {{ 'CROP.SINGLE'| translate}} {{plotCode}}
</h1>
<div class="container">
  <div class="row">
    <div class="col">
      <mat-card class="w-full my-3" id="cardsBottom">
        <div class="row">
          <div class="col-3">
            <div id="tabIcon"
              class="p-3 absolute left-0 ml-3 top-2 img-fluid rounded-rectangle img-thumbnail shadow-md">
              <i class="fa-solid fa-map" style="font-size:xx-large; width: auto;"></i>
            </div>
          </div>
          <div class="col-9 p-2">
            <div class="grid grid-cols-1 h-full">
              <ng-container *ngFor="let column of displayedColumns">
                <div class="mx-4 flex justify-stretch items-stretch text-lg h-full">
                  <div id="titlesTexts">{{ getColumnLabel(column) | translate }}: &emsp;
                  </div>
                  <div id="valuesTexts">&emsp;{{ plot?.[column] }}
                  </div>
                  <div *ngFor="let eco of plot.eco_schemes" id="valuesTexts">
                    <span
                      *ngIf="eco.scheme_selectable == true && eco.scheme_selected == true && column.includes('eco_schemes')"
                      style="margin-top: -7%;">
                      <div class="w-full float-right">
                        {{eco.scheme_code}}
                        <span class="p-2">|</span>
                      </div>
                    </span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col">
      <div class="w-full my-3" id="cardsBottom">
        <div id="map" class="my-3"></div>
      </div>
    </div>
  </div>

  <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center">
    <mat-tab label="First">
      <ng-template mat-tab-label>
        <div class="row">
          <div class="col-6 d-flex align-items-left justify-content-left">
            <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail"
              src="../../../assets/../assets/sensors/iotDevice2.png" alt=""
              style="background-color: #00bbff7d;
                     border: 3px solid rgb(253, 255, 252);
                     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;" width="40">
          </div>
          <div class="col-6 d-flex align-items-center justify-content-center">
            {{'SINGLEPLOT.SENSOR_INFORMATION'| translate }}
          </div>
        </div>
      </ng-template>
      <div class="row">
        <div class="col-5" id="menuSenors">
          <mat-card style="width: fit-content;" class="mt-2 mb-2" id="cardButtons">
            <div class="container" *ngFor="let plotDevice of plotDevices">
              <div class="row">
                <mat-button-toggle-group multiple id="toggleButtons">
                  <div class="row">
                    <div class="col text-center d-flex align-items-center justify-content-center"
                      style="font-weight: 600; font-size: medium; color: rgb(173, 173, 173);">
                      Choose Device
                    </div>
                    <div class="col p-2" *ngFor="let device of plotDevice.devices; let i = index">
                      <mat-button-toggle class="text-center" (change)="chooseDevice($event, device)"
                        [ngClass]="{ 'buttonOn': buttonStates[device], 'buttonOff': !buttonStates[device] }"
                        [checked]="buttonStates[device]">
                        <span class="mr-2">Device {{ i + 1 }}</span>
                        <div class="rounded-circle" id="circleButtonOn"></div>
                      </mat-button-toggle>
                    </div>
                  </div>
                </mat-button-toggle-group>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="col-2 text-center d-flex align-items-center justify-content-center">
          <mat-spinner *ngIf="spinner" diameter="40" color="accent"></mat-spinner>
        </div>
        <div class="col-5" id="menuSenors2" *ngIf="sensorsButtons">
          <mat-card style="width: fit-content;" class="mt-2 mb-2" id="cardButtons">
            <div class="container">
              <div class="row" *ngFor="let sens of componentsD; let groupIndex = index">
                <mat-button-toggle-group multiple id="toggleButtons">
                  <div class="row">
                    <div class="col p-2" *ngFor="let button of sens.attributes.components">
                      <mat-button-toggle class="text-center" (change)="chooseSensor($event, button, groupIndex, sens)"
                        [ngClass]="{ 'buttonOn': button.isOn, 'buttonOff': !button.isOn }"
                        [checked]="buttonStates2[groupIndex] && buttonStates2[groupIndex][button.sensor_type]">
                        <span class="mr-2">{{ button.sensor_type }}</span>
                        <div class="rounded-circle" id="circleButtonOn"></div>
                      </mat-button-toggle>
                    </div>
                    <div class="col text-center d-flex align-items-center justify-content-center"
                      style="font-weight: 600; font-size: medium; color: rgb(173, 173, 173);">
                      Choose Sensor
                    </div>
                  </div>
                </mat-button-toggle-group>
              </div>
            </div>
          </mat-card>
        </div>


      </div>

      <div class="row" style="margin-top: -25px;">
        <div class="col-6">
          <mat-card id="cardsBottom2" class="my-4">
            <div class="container my-2" id="scrollableTable">
              <div class="py-2" id="tableValues">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-md text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr style="background-color: white;">
                      <th scope="col" class="px-6 py-3 text-left" id="tableHead" style="border-top-left-radius: 8px;">
                        {{'SENSORS.SENSOR_NAME'| translate }}</th>
                      <th scope="col" class="px-6 py-3 text-right" id="tableHead">
                        {{'SENSORS.SENSOR_LAST_MEASUREMENT'| translate }}</th>
                      <th scope="col" class="px-6 py-3 text-center" id="tableHead">
                        {{'SENSORS.SENSOR_UNIT'| translate }}</th>
                      <th scope="col" class="px-6 py-3 text-center" id="tableHead"
                        style="border-top-right-radius: 8px;">{{'SENSORS.SENSOR_UPDATE'| translate }}
                      </th>
                    </tr>
                  </thead>
                  <ng-container *ngFor="let comp of lastDeviceComponents">
                    <tbody *ngIf="comp.attributes.renke">
                      <tr *ngFor="let entry of comp.attributes.renke">
                        <ng-container>
                          <td class="px-2 py-1 text-left">{{ entry.name}}</td>
                          <td class="px-2 py-1 text-right">{{ entry.value }}</td>
                          <td class="px-2 py-1 text-right">{{ entry.unit }}</td>
                          <td class="px-2 py-1 text-right">{{ comp.attributes.measurement_time | date:
                            'medium'}}</td>
                        </ng-container>
                      </tr>
                    </tbody>
                    <tbody *ngIf="comp.attributes.terros_12">
                      <tr *ngFor="let entry of comp.attributes.terros_12">
                        <ng-container>
                          <td class="px-2 py-1 text-left">{{ entry.name}}</td>
                          <td class="px-2 py-1 text-right">{{ entry.value }} {{entry.unit}}</td>
                          <td class="px-2 py-1 text-right">{{ entry.unit }}</td>
                          <td class="px-2 py-1 text-right">{{ comp.attributes.measurement_time | date:
                            'medium'}}</td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </ng-container>
                </table>
                <hr>
              </div>
            </div>
          </mat-card>
        </div>
        <div class="col-6">
          <mat-card class="my-4 p-2 mr-4" id="cardsBottom2">
            <canvas #lineCanvas style="margin-left: -7px;"></canvas>
          </mat-card>
        </div>
      </div>
    </mat-tab>


    <mat-tab label="Second">
      <ng-template mat-tab-label>
        <div class="row">
          <div class="col-6 d-flex align-items-left justify-content-left">
            <img class=" img-fluid mx-auto rounded-rectangle img-thumbnail" src="../../../../assets/land.png" alt=""
              style="background-color: #43ff8c;
                     border: 3px solid rgb(253, 255, 252);
                     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;"
              width="40">
          </div>
          <div class="col-6 d-flex align-items-center justify-content-center">
            {{'SINGLEPLOT.PLOT_INFORMATION'| translate }} 
          </div>
        </div>
      </ng-template>
      <div class="my-2">
        <mat-accordion>
          <ng-container *ngFor="let info of plot.crop_info; let i = index">
            <mat-expansion-panel class="mt-2 sm:py-6 md:py-4 lg:py-2" [expanded]="expandedPanelIndex === i" hideToggle>
              <mat-expansion-panel-header (click)="togglePanel(i)">
                <mat-panel-title>
                  <mat-card-header class="mb-3">
                    <div class="col-6">
                      <i class="fa-solid fa-wheat-awn text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-md"
                        id="crop_icon"></i>
                    </div>
                  </mat-card-header>
                  <div class="mr-1">
                    #{{ i + 1 }}
                  </div>
                  <div class="ml-1">
                    {{info.crop_type}}
                  </div>
                </mat-panel-title>
                <mat-panel-description>
                  {{info.variety}}
                </mat-panel-description>
                <mat-icon class="mt-6 mr-1 text-gray-500"
                  [class.rotate-icon]="expandedPanelIndex === i">keyboard_arrow_down</mat-icon>
              </mat-expansion-panel-header>
              <div class="lg:flex my-4">
                <div class="lg:w-4/12">
                  <div class="m-1 justify-around">
                    <div class="grid grid-cols-2 text-lg ">
                      <div class="font-bold"> {{ 'PLOTS.M/X' | translate }}: &emsp;</div>
                      <div>&emsp; {{info.dry_xeric}}</div>
                    </div>
                    <div class="grid grid-cols-2 text-lg">
                      <div class="font-bold"> {{ 'SINGLEPLOT.PEF' | translate }}: &emsp;</div>
                      <div>&emsp; {{info.pef | number: '1.2-2'}}</div>
                    </div>
                    <div class="grid grid-cols-2 text-lg">
                      <div class="font-bold"> {{ 'SINGLEPLOT.PRODTREE' | translate }}: &emsp;</div>
                      <div>&emsp; {{info.prod_tree_number}}</div>
                    </div>
                  </div>
                </div>
                <div class="lg:w-8/12 grid grid-cols-1">
                  <div [hidden]="treatmentData.length === 0">
                    <mat-table [dataSource]="treatmentData">
                      <ng-container matColumnDef="lastTreatment">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af; border-top-left-radius: 1rem !important;">
                          <img src="../../../assets/agriculture.png" style="background-color: rgb(255, 181, 255);"
                            class="w-9 text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-md">
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">
                          {{'SINGLEPLOT.LTREATMENT' | translate}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="treatment">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af;">
                          {{'SINGLEPLOT.TREATMENT' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">{{ row.treatment }}</td>
                      </ng-container>
                      <ng-container matColumnDef="start_date">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af;">
                          {{'SINGLEPLOT.STARTDATE' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">
                          {{ row.start_date | date: 'dd/MM/yyyy h:mm a'}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="end_date">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af; border-top-right-radius: 1rem !important;">
                          {{'SINGLEPLOT.ENDDATE' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">
                          {{ row.end_date | date: 'dd/MM/yyyy h:mma'}}
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="treatmentColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: treatmentColumns">
                      </tr>
                    </mat-table>
                  </div>

                  <div [hidden]="irrigationData.length === 0">
                    <mat-table [dataSource]="irrigationData">
                      <ng-container matColumnDef="lastIrrigation">
                        <th mat-header-cell *matHeaderCellDef class="header-cell"
                          style="background-color: #a1c6af; border-top-left-radius: 1rem !important;">
                          <img src="../../../assets/irrigation_manag.png"
                            class="w-9 text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-md"
                            style="background-color: #add1ff;">
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">
                          {{'SINGLEPLOT.LIRRIGATION' | translate}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="irrigation_method">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                            background-color: #a1c6af;">
                          {{'SINGLEPLOT.IRRMETHOD' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">{{ row.irrigation_method }}</td>
                      </ng-container>
                      <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                            background-color: #a1c6af; ">
                          {{'SINGLEPLOT.QUANTITY' | translate}} (m<sup>3</sup>)
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell" class="header-cell">{{ row.quantity |
                          number
                          :
                          '1.2-2' }}</td>
                      </ng-container>
                      <ng-container matColumnDef="datetime">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                            background-color: #a1c6af; border-top-right-radius: 1rem !important;">
                          {{'SINGLEPLOT.DATE' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell" class="header-cell">{{ row.datetime |
                          date:
                          'dd/MM/yyyy h:mm a' }}</td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="irrigationColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: irrigationColumns">
                      </tr>
                    </mat-table>
                  </div>

                  <div [hidden]="fertilisationData.length === 0">
                    <mat-table [dataSource]="fertilisationData">
                      <ng-container matColumnDef="lastFertilisation">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af; border-top-left-radius: 1rem !important;">
                          <img src="../../../assets/fertilizer_manage.png"
                            class="w-9 text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-md"
                            style="background-color: #eac406;">
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell" class="header-cell">
                          {{'SINGLEPLOT.LFERT' | translate}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="chemical_composition">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af;">
                          {{ 'SINGLEPLOT.CHEMICAL' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell" class="header-cell">{{
                          row.chemical_composition
                          }}</td>
                      </ng-container>
                      <ng-container matColumnDef="amount_applied">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af;">{{ 'SINGLEPLOT.AMOUNT' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell" class="header-cell">{{ row.amount_applied
                          |
                          number: '1.2-2' }} {{
                          row.amount_measurement_units }}</td>
                      </ng-container>
                      <ng-container matColumnDef="datetime">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af; border-top-right-radius: 1rem !important;">
                          {{'SINGLEPLOT.DATE' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell" class="header-cell">{{ row.datetime |
                          date:
                          'dd/MM/yyyy h:mm a' }}</td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="fertilisationColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: fertilisationColumns">
                      </tr>
                    </mat-table>
                  </div>

                  <div [hidden]="sprayingData.length === 0">
                    <mat-table [dataSource]="sprayingData">
                      <ng-container matColumnDef="lastSpraying">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af; border-top-left-radius: 1rem !important;">
                          <img src="../../../assets/agro_protection.png" style="background-color: #8deda3;"
                            class="w-9 text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-md">
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell" class="header-cell">
                          {{'SINGLEPLOT.LSPRAYING' | translate}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="commercial_name">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af;">
                          {{ 'SINGLEPLOT.NAME' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell" class="header-cell">{{
                          row.commercial_name }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="amount_applied">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af;">{{ 'SINGLEPLOT.AMOUNT' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell" class="header-cell">{{ row.amount_applied
                          |
                          number: '1.2-2' }} {{
                          row.amount_measurement_units }}</td>
                      </ng-container>
                      <ng-container matColumnDef="datetime">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af; border-top-right-radius: 1rem !important;">
                          {{'SINGLEPLOT.DATE' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell" class="header-cell">{{ row.datetime |
                          date:
                          'dd/MM/yyyy h:mm a' }}</td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="sprayingColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: sprayingColumns">
                      </tr>
                    </mat-table>
                  </div>
                  <div [hidden]="harvestData.length === 0">
                    <mat-table [dataSource]="harvestData">
                      <ng-container matColumnDef="lastHarvest">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af; border-top-left-radius: 1rem !important;">
                          <img src="../../../assets/harvest_fruits.png" style="background-color: #d7d292"
                            class="w-9 text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-md">
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">
                          {{'SINGLEPLOT.LHARVEST' | translate}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="cleaning_of_harvesting_equipment">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af;">
                          {{ 'SINGLEPLOT.CLEANING' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">{{ row.cleaning_of_harvesting_equipment
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="summ">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af;">{{ 'SINGLEPLOT.AMOUNT' | translate}} (Kg)
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">{{ row.summ | number: '1.2-2' }} {{
                          row.summ_unit_measurement }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="datetime">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af; border-top-right-radius: 1rem !important;">
                          {{'SINGLEPLOT.DATE' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">
                          {{ row.datetime | date: 'dd/MM/yyyy h:mma'}}
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="harvestColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: harvestColumns">
                      </tr>
                    </mat-table>
                  </div>
                  <div [hidden]="residuesData.length === 0">
                    <mat-table [dataSource]="residuesData">
                      <ng-container matColumnDef="lastResidues">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af; border-top-left-radius: 1rem !important;">
                          <img src="../../../assets/compost.png" style="background-color: #bad6be"
                            class="w-9 text-center img-fluid mx-auto rounded-rectangle img-thumbnail shadow-md">
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">
                          {{'SINGLEPLOT.LRESIDUES' | translate}}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="management_method">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af;">
                          {{ 'SINGLEPLOT.IRRMETHOD' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">{{ row.management_method }}</td>
                      </ng-container>
                      <ng-container matColumnDef="total_amount">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af;">{{ 'SINGLEPLOT.AMOUNT' | translate}} (Kg)
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">{{ row.total_amount | number: '1.2-2' }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="datetime">
                        <th mat-header-cell *matHeaderCellDef class="header-cell" style="
                          background-color: #a1c6af; border-top-right-radius: 1rem !important;">
                          {{'SINGLEPLOT.DATE' | translate}}
                        </th>
                        <td mat-cell *matCellDef="let row" class="header-cell">
                          {{ row.datetime | date: 'dd/MM/yyyy h:mma'}}
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="residuesColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: residuesColumns">
                      </tr>
                    </mat-table>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>